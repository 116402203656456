export enum ApiErrors {
  BaseError = 'BaseError',
  AuthenticationError = 'AuthenticationError',
  AuthorizationError = 'AuthorizationError',
  CommonError = 'CommonError',
  DatabaseError = 'DatabaseError',
  ExpiredTokenError = 'ExpiredTokenError',
  FileUploadError = 'FileUploadError',
  InvalidApiPathError = 'InvalidApiPathError',
  InvalidCredentialsError = 'InvalidCredentialsError',
  InvalidJsonError = 'InvalidJsonError',
  InvalidParameterError = 'InvalidParameterError',
  InvalidTenantError = 'InvalidTenantError',
  InvalidTokenError = 'InvalidTokenError',
  InputValidationError = 'InputValidationError',
  LibraryError = 'LibraryError',
  LibraryNotInitializedError = 'LibraryNotInitializedError',
  MethodNotImplementedError = 'MethodNotImplementedError',
  MissingInfoError = 'MissingInfoError',
  ObjectExistsError = 'ObjectExistsError',
  ObjectNotFoundError = 'ObjectNotFoundError',
  ODataError = 'ODataError',
  PasswordExpiredError = 'PasswordExpiredError',
  RestError = 'RestError',
  UnknownError = 'UnknownError',
  AddressNotFoundError = 'AddressNotFoundError',
  AlfaBetaDataError = 'AlfaBetaDataError',
  AuthenticationBlockedAccountError = 'AuthenticationBlockedAccountError',
  AuthenticationDisabledAccountError = 'AuthenticationDisabledAccountError',
  AuthenticationInactiveAccountError = 'AuthenticationInactiveAccountError',
  BeneficiaryInactiveError = 'BeneficiaryInactiveError',
  BeneficiaryInvalidCoverageProgramError = 'BeneficiaryMissingCoverageProgramError',
  BeneficiaryMissingCoverageProgramError = 'BeneficiaryMissingCoverageProgramError',
  BeneficiaryTreatmentInvalidCoverageProgramError = 'BeneficiaryTreatmentInvalidCoverageProgramError',
  BeneficiaryTreatmentInvalidMonodrugError = 'BeneficiaryTreatmentInvalidMonodrugError',
  BeneficiaryDuplicate = 'BeneficiaryDuplicate',
  CuilGeneratorInvalidGenderError = 'CuilGeneratorInvalidGenderError',
  DatabaseRelationConstraintError = 'DatabaseRelationConstraintError',
  DistributeMedicationDrugCompanyError = 'DistributeMedicationDrugCompanyError',
  DistributeBillingRuleError = 'DistributeBillingRuleError',
  DistributeMultipleDrugCompaniesError = 'DistributeMultipleDrugCompaniesError',
  DistributePharmacyAgreementRuleError = 'DistributePharmacyAgreementRuleError',
  DistributePharmacyDrugCompanyError = 'DistributePharmacyDrugCompanyError',
  FinancierInactiveError = 'FinancierInactiveError',
  InvalidDateLaterError = 'InvalidDateLaterError',
  InvalidDateRangeError = 'InvalidDateRangeError',
  InvalidDistributionTypeError = 'InvalidDistributionTypeError',
  InvalidStatusError = 'InvalidStatusError',
  PasswordChangeSameError = 'PasswordChangeSameError',
  PasswordFormatError = 'PasswordFormatError',
  PasswordLengthError = 'PasswordLengthError',
  PasswordUsedError = 'PasswordUsedError',
  SisaConditionError = 'SisaConditionError',
  PharmacyInactiveError = 'PharmacyInactiveError',
  RedispenseInactiveError = 'RedispenseInactiveError',
  PrescriptionFinancierShowCommercialBrandError = 'PrescriptionFinancierShowCommercialBrandError',
  PrescriptionAuthorizeStatusError = 'PrescriptionAuthorizeStatusError',
  PrescriptionAuthorizedAmountError = 'PrescriptionAuthorizedAmountError',
  PrescriptionNotifyPharmacyError = 'PrescriptionNotifyPharmacyError',
  PrescriptionReturnStatusError = 'PrescriptionReturnStatusError',
  PrescriptionValidateStatusError = 'PrescriptionValidateStatusError',
  PrescriptionUniqueCoverageProgramError = 'PrescriptionUniqueCoverageProgramError',
  UserEntityTypeDoctorError = 'UserEntityTypeDoctorError',
  UserEntityTypeUnauthorizedError = 'UserEntityTypeUnauthorizedError',
  PrescriptionMedicationWasNotFoundOnCoverageProgramError = 'PrescriptionMedicationWasNotFoundOnCoverageProgramError',
  DistributeBillingRuleNotFound = 'DistributeBillingRuleNotFound',
  LiquidationNotifyError = 'LiquidationNotifyError',
  BeneficiaryHasPrescriptionsError = 'BeneficiaryHasPrescriptionsError',
}
