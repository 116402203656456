import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { map, tap } from 'rxjs/operators';
import { ApiList, EntityFile, Pharmacy } from '../models';
import { getHttpParams, parseUtcDateProperties, processApiList } from '../utils';
import { EntityFileApiHelperService } from './entity-file-api-helper.service';
import { EntityFileApiService } from './entity-file-api-service.type';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class PharmacyApiService extends ResourceEntityApiService<Pharmacy> implements EntityFileApiService {
  resourceUrl = 'pharmacies';

  constructor(protected http: HttpClient,
              protected entityFileApiHelperService: EntityFileApiHelperService) {
    super(http);
  }

  getFinancierPharmacies(financierId, coverageProgramId?, queryParams?, searchParam?, distributionType?, medicationsIds? , pharmacyId?) {
    let httpParams = getHttpParams(queryParams);
    if (coverageProgramId) {
      httpParams = httpParams.set('coverageProgramId', coverageProgramId.toString());
    }
    if (searchParam) {
      httpParams = httpParams.set('searchParam', searchParam);
    }
    if (distributionType) {
      httpParams = httpParams.set('distributionType', distributionType);
    }
    if (pharmacyId) {
      httpParams = httpParams.set('pharmacyId', pharmacyId);
    }
    if (medicationsIds) {
      httpParams = httpParams.set('medicationsIds', medicationsIds);
    }
    return this.http.get<ApiList<Pharmacy>>(
      `${environment.apiUrl}/financiers/${financierId}/pharmacies`,
      { params: httpParams }
    ).pipe(
      tap(processApiList),
      map(list => this.parseList(list))
    );
  }

  getByPharmacyGroupId(byPharmacyGroupId?, pharmacyId?, queryParams?) {
    let httpParams = getHttpParams(queryParams);
    if (byPharmacyGroupId) {
      httpParams = httpParams.set('byPharmacyGroupId', byPharmacyGroupId.toString());
    }
    if (pharmacyId) {
      httpParams = httpParams.set('pharmacyId', pharmacyId.toString());
    }
    return this.http.get<ApiList<Pharmacy>>(
      `${environment.apiUrl}/pharmacies/${byPharmacyGroupId}/${pharmacyId}/group`,
      { params: httpParams }
    ).pipe(
      tap(processApiList),
      map(list => this.parseList(list))
    );
  }

  parseEntity(entity) {
    parseUtcDateProperties(entity, ['startDate', 'endDate']);
    return entity;
  }

  getAllFiles(entityParams, queryParams?) {
    return this.entityFileApiHelperService.getAllFiles(this, entityParams, queryParams);
  }

  getFileById(entityParams, fileId) {
    return this.entityFileApiHelperService.getFileById(this, entityParams, fileId);
  }

  uploadFile(entityParams, files) {
    return this.entityFileApiHelperService.uploadFile(this, entityParams, files);
  }

  deleteFile(entityParams, fileId) {
    return this.entityFileApiHelperService.deleteFile(this, entityParams, fileId);
  }

  downloadFile(file: EntityFile) {
    this.entityFileApiHelperService.downloadFile(this, file);
  }

  getAgreementRulesByCoverageProgramAndFinancierId(pharmacyId, coverageProgramId, financierId) {
    return this.http.get<any>(`${environment.apiUrl}/${this.resourceUrl}/${pharmacyId}/coverageProgramId/${coverageProgramId}/financierId/${financierId}/agreementRules`);
  }

  getFinancierPharmacyById(financierId, pharmacyId, coverageProgramId?, queryParams?, distributionType?, medicationsIds?) {
    let httpParams = getHttpParams(queryParams);
    if (coverageProgramId) {
      httpParams = httpParams.set('coverageProgramId', coverageProgramId.toString());
    }
    if (distributionType) {
      httpParams = httpParams.set('distributionType', distributionType);
    }
    if (pharmacyId) {
      httpParams = httpParams.set('pharmacyId', pharmacyId);
    }
    if (medicationsIds) {
      httpParams = httpParams.set('medicationsIds', medicationsIds);
    }
    return this.http.get<Pharmacy>(
      `${environment.apiUrl}/financiers/${financierId}/pharmacy/${pharmacyId}`, 
      { params: httpParams }).pipe(map(entity => this.parseEntity({ ...entity })
    )
    );
  }
}
