import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { LocaleSettings } from 'primeng/calendar';
import { I18nService } from '../../services/i18n.service';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarComponent implements OnInit {
  @Input() value: any;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() label: string;
  @Input() appendTo: string;

  @Output() valueChange = new EventEmitter<any>();

  @ViewChild('input') input: ElementRef;

  locale: LocaleSettings;

  constructor(private i18nService: I18nService) {
  }

  ngOnInit(): void {
    this.locale = this.i18nService.getCalendarLocale();
  }

  @HostListener('focus')
  onFocus() {
    this.input.nativeElement.focus();
  }
}
