import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PotencyUnit } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class PotencyUnitApiService extends ResourceEntityApiService<PotencyUnit> {
  resourceUrl = 'potency-units';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
