import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { tap } from 'rxjs/operators';
import { ApiList, MedicationPrice, QueryParams } from '../models';
import { getHttpParams, parseUtcDateProperties, processApiList } from '../utils';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class MedicationPriceApiService extends ResourceEntityApiService<MedicationPrice> {
  constructor(protected http: HttpClient) {
    super(http);
  }

  public getAll(queryParams?: QueryParams, params?: any) {
    return this.http.get<ApiList<MedicationPrice>>(
      `${environment.apiUrl}/medications/${params}/price-history`,
      { params: getHttpParams(queryParams) }
    ).pipe(tap(processApiList));
  }

  parseEntity(entity) {
    parseUtcDateProperties(entity, ['date']);
    return entity;
  }
}
