import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuditedAccountableItem, QueryParams } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';
import { environment } from '@environment';
import { getHttpParams } from '../utils';

@Injectable()
export class AuditedAccountableItemApiService extends ResourceEntityApiService<AuditedAccountableItem> {
  resourceUrl = 'audited-accountable-items';

  constructor(protected http: HttpClient) {
    super(http);
  }

  parseEntity(entity) {;
    return entity;
  }

  public createExecutedBillingDocument(data, queryParams?: QueryParams) {
    return this.http.post<any>(
      `${environment.apiUrl}/${this.resourceUrl}/createExecutedBillingDocument`,
      data, { params: getHttpParams(queryParams) }
    );
  }

  public createExecutedRefundingDocument(data, queryParams?: QueryParams) {
    return this.http.post<any>(
      `${environment.apiUrl}/${this.resourceUrl}/createExecutedRefundingDocument`,
      data, { params: getHttpParams(queryParams) }
    );
  }

}
