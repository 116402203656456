import { ApiList, ResponseMetadata } from '../models';

export function processApiList<T>(apiList: ApiList<T>): ApiList<T> {
  apiList.data = apiList.data || [];
  apiList.meta = apiList.meta || {} as ResponseMetadata;

  if (apiList.meta.inlineCount != null) {
    apiList.meta.total = apiList.meta.inlineCount;
    apiList.meta.first = apiList.meta.skip + 1;
    apiList.meta.last = Math.min(apiList.meta.skip + apiList.meta.top, apiList.meta.total);
  }

  return apiList;
}
