import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Vademecum } from '../models';
import { EntityFileApiHelperService } from './entity-file-api-helper.service';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class VademecumApiService extends ResourceEntityApiService<Vademecum> {
  resourceUrl = 'vademecums';

  constructor(
    protected http: HttpClient,
    protected entityFileApiHelperService: EntityFileApiHelperService
    ) {
    super(http);
  }

  getVademecumsByMedication(medicationId) {
    return this.http.get(`${environment.apiUrl}/vademecums/medication/${medicationId}`);
  }
}
