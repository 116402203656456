import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ApiList, Doctor, DoctorLicense, QueryParams } from '../models';
import { getHttpParams, processApiList } from '../utils';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class DoctorApiService extends ResourceEntityApiService<Doctor> {
  resourceUrl = 'doctors';

  constructor(protected http: HttpClient) {
    super(http);
  }

  searchByLicense(license: string, queryParams?: QueryParams): Observable<ApiList<DoctorLicense>> {
    return this.http.get<ApiList<Doctor>>(
      `${environment.apiUrl}/doctors/licenses/${license}`,
      { params: getHttpParams(queryParams) }
    ).pipe(tap(processApiList));
  }
}
