import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { tap } from 'rxjs/operators';
import { ApiList, Monodrug } from '../models';
import { getHttpParams, processApiList } from '../utils';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class MonodrugApiService extends ResourceEntityApiService<Monodrug> {
  resourceUrl = 'monodrugs';

  constructor(protected http: HttpClient) {
    super(http);
  }

  getCoverageProgramMonodrugs(coverageProgramId, specialCoverage, queryParams) {
    return this.http
      .get<ApiList<Monodrug>>(
        `${environment.apiUrl}/coverage-programs/${coverageProgramId}/specialCoverage/${specialCoverage}/monodrugs`,
        { params: getHttpParams(queryParams) }
      )
      .pipe(tap(processApiList));
  }
}
