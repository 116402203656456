import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { ApiList, Liquidation, QueryParams, EntityFile, } from '../models';
import {
  getHttpParams,
  parseUtcDateProperties,
  processApiList,
} from '../utils';
import { ResourceEntityApiService } from './resource-entity-api-service';
import { LiquidationLog } from '@app/shared/api/models/liquidation-log';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EntityFileApiHelperService } from './entity-file-api-helper.service';

@Injectable()
export class LiquidationApiService extends ResourceEntityApiService<Liquidation> {
  resourceUrl = 'liquidations';

  constructor(
    protected http: HttpClient,
    protected entityFileApiHelperService: EntityFileApiHelperService
  ) {
    super(http);
  }

  parseEntity(entity) {
    parseUtcDateProperties(entity, [
      'invoiceDate',
      'receptionDate',
      'paymentDate',
      'expirationDate',
    ]);
    return entity;
  }

  exportAmerica(data) {
    return this.http.post<any>(
      `${environment.apiUrl}/${this.resourceUrl}/exportAmerica`,
      data
    );
  }

  processLiquidation(data, id) {
    return this.http.put<any>(
      `${environment.apiUrl}/${this.resourceUrl}/${id}/process`,
      data
    );
  }

  getLogs(liquidationId: number, queryParams?: QueryParams) {
    return this.http
      .get<ApiList<LiquidationLog>>(
        `${this.getItemUrl({ id: liquidationId })}/logs`,
        { params: getHttpParams(queryParams) }
      )
      .pipe(tap(processApiList));
  }

  payLiquidation(data: any, id: any) {
    return this.http
      .put<any>(`${environment.apiUrl}/${this.resourceUrl}/${id}/pay`, data)
      .pipe(map((entity) => entity && this.parseEntity({ ...entity })));
  }

  getAllFiles(entityParams, queryParams?) {
    return this.entityFileApiHelperService.getAllFiles(
      this,
      entityParams,
      queryParams
    );
  }

  changeLiquidationStatus(data: any, id: any) {
    return this.http
      .put<any>(`${environment.apiUrl}/${this.resourceUrl}/${id}/changeStatus`, data)
      .pipe(map((entity) => entity && this.parseEntity({ ...entity })));
  }

  uploadFile(entityParams, files) {
    return this.entityFileApiHelperService.uploadFile(this, entityParams, files);
  }

  deleteFile(entityParams, fileId) {
    return this.entityFileApiHelperService.deleteFile(this, entityParams, fileId);
  }

  downloadFile(file: EntityFile) {
    this.entityFileApiHelperService.downloadFile(this, file);
  }

  notify(liquidationId, data) {
    return this.http.post(`${this.getCollectionUrl()}/${liquidationId}/notify`, data);

  }

}
