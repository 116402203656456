import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileSaverService } from '@app/shared/file-saver';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  ApiList,
  DistributeReception,
  DistributeShip,
  DistributionOrder,
  PrescriptionItem,
} from '../models';
import { getHttpParams, processApiList } from '../utils';

interface ControlledMedicationResponse {
  isControlled: boolean;
  prescriptionItemId: number;
  medicationName: string;
}

@Injectable()
export class DistributeApiService {
  constructor(
    protected http: HttpClient,
    protected fileSaverService: FileSaverService
  ) {}

  getPendingItems(queryParams) {
    return this.http
      .get<ApiList<PrescriptionItem>>(
        `${environment.apiUrl}/distribute/pending`,
        { params: getHttpParams(queryParams) }
      )
      .pipe(tap(processApiList));
  }

  getShippedItems(queryParams) {
    return this.http
      .get<ApiList<PrescriptionItem>>(
        `${environment.apiUrl}/distribute/shipped`,
        { params: getHttpParams(queryParams) }
      )
      .pipe(tap(processApiList));
  }

  getRequestedItems(queryParams) {
    return this.http
      .get<ApiList<PrescriptionItem>>(
        `${environment.apiUrl}/distribute/requested`,
        { params: getHttpParams(queryParams) }
      )
      .pipe(tap(processApiList));
  }

  getAllItems(queryParams) {
    return this.http
      .get<ApiList<PrescriptionItem>>(`${environment.apiUrl}/distribute/all`, {
        params: getHttpParams(queryParams),
      })
      .pipe(tap(processApiList));
  }

  order(order: DistributionOrder) {
    return this.http.post<any>(
      `${environment.apiUrl}/distribute/orders`,
      order
    );
  }
  
  getOrderStatus(prescriptionItem: number) {
    return this.http.get<any>(
      `${environment.apiUrl}/distribute/orders/${prescriptionItem}/status`
    );
  }

  checkControlledMedications(prescriptionItemId: number): Observable<ControlledMedicationResponse> {
    return this.http.get<ControlledMedicationResponse>(
      `${environment.apiUrl}/distribute/prescriptionItems/${prescriptionItemId}/controlled`
    );
  }

  ship(ship: DistributeShip) {
    return this.http.post<any>(`${environment.apiUrl}/distribute/ship`, ship);
  }

  receive(reception: DistributeReception) {
    return this.http.post<any>(
      `${environment.apiUrl}/distribute/receive`,
      reception
    );
  }

  updateItemStatus(order: DistributionOrder) {
    const payload = { order, status: 'Requested' };
    return this.http.put<any>(`${environment.apiUrl}/distribute/updateStatus`, payload);
  }

  notifySAP(orderId: number) {
    return this.http.post<any>(
      `${environment.apiUrl}/distribute/orders/${orderId}/notifySAP`,
      {}
    );
  }

  getOrderReport(orderId: number) {
    return this.http.get(
      `${environment.apiUrl}/distribute/orders/${orderId}/report`,
      {
        observe: 'response',
        responseType: 'blob',
      }
    );
  }

  downloadOrderReport(orderId: number) {
    return this.getOrderReport(orderId).pipe(
      tap((res) => {
        const filename = res.headers
          .get('content-disposition')
          .split(';')
          .find((n) => n.includes('filename='))
          .replace('filename=', '')
          .replace(/['"]/g, '')
          .trim();
        this.fileSaverService.saveAs(res.body, filename);
      })
    );
  }

  downloadOrderReportByFile(file) {
    const blob = new Blob([file.stream], { type: 'application/vnd.ms-excel' });
    let filename = file.metadata.fileName;
    this.fileSaverService.saveAs(blob, filename);
  }
}
