import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DispatchItem } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';
import { parseUtcDateProperties } from '../utils';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DispatchItemApiService extends ResourceEntityApiService<DispatchItem> {
  resourceUrl = 'dispatch-items';

  constructor(protected http: HttpClient) {
    super(http);
  }

  parseEntity(entity) {
    parseUtcDateProperties(entity.dispatch, ['documentsReceptionDate']);
    return entity;
  }

  getActualBilledPercent(dispatchItemId: number) {
    return this.http.get<any>(
      `${environment.apiUrl}/dispatch-items/${dispatchItemId}/billedPercent`
    );
  }

  updateBilledPercent(actualBilledPercent: number, dispatchItemId:number): Observable<DispatchItem> {
    return this.http.put<DispatchItem>(`${environment.apiUrl}/dispatch-items/${dispatchItemId}/updateBilledPercent`, {
      actualBilledPercent
    }).pipe(
      map(entity => entity && this.parseEntity({ ...entity }))
    );
  }
}
