<div class="exception-body notfound-page">
  <div class="exception-type">
    <img src="assets/layout/images/404.svg">
  </div>

  <div class="card exception-panel">
    <i class="material-icons">&#xE001;</i>
    <h1>{{ lang.SHARED.PAGES.NOT_FOUND.title | translate }}</h1>
    <div class="exception-detail">{{ lang.SHARED.PAGES.NOT_FOUND.description | translate }}</div>
    <p-button label="Homepage" [routerLink]="['/']" styleClass="pink-btn"></p-button>
  </div>
</div>
