import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { BehaviorSubject, Observable, Observer, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { ApiErrors } from '../errors/api-errors';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenEndpoint = 'users/refresh-token';
  private authEndpoints = [
    'users/login',
    'users/forgot-password',
    'users/reset-password',
    this.refreshTokenEndpoint,
  ];

  private refreshTokenInProgress = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  constructor(private authenticationService: AuthenticationService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!this.isAuthEndpoint(request.url)) {
      this.authenticationService.getActiveSessionTime();
      request = this.addAuthenticationToken(request);
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (this.isAuthEndpoint(request.url)) {
          if (request.url.includes(this.refreshTokenEndpoint)) {
            this.logout();
            return;
          }
          return throwError(error);
        }

        if (
          error.error instanceof Blob &&
          error.error.type === 'application/json'
        ) {
          return new Observable((observer: Observer<any>) => {
            const reader = new FileReader();
            reader.onload = (e: Event) => {
              try {
                const errorData = JSON.parse((e.target as any).result);

                if (errorData.name) {
                  if (
                    error.status === 403 &&
                    errorData.name === ApiErrors.ExpiredTokenError
                  ) {
                    this.logout();
                    return;
                    // This line is commented out because the refreshToken is deprecated in the project
                    // this.handleRefreshToken(request, next).subscribe(
                    //   (result) => {
                    //     observer.next(result);
                    //   },
                    //   (error2) => {
                    //     observer.error(error2);
                    //   },
                    //   () => {
                    //     observer.complete();
                    //   }
                    // );
                  } else if (
                    error.status === 401 &&
                    errorData.name === ApiErrors.InvalidTokenError
                  ) {
                    this.logout();
                    observer.complete();
                  } else {
                    observer.error(error);
                    observer.complete();
                  }
                }
              } catch (e) {
                observer.error(error);
                observer.complete();
              }
            };
            reader.onerror = () => {
              observer.error(error);
              observer.complete();
            };
            reader.readAsText(error.error);
          });
        }

        if (error.error && error.error.name) {
          if (
            error.status === 403 &&
            error.error.name === ApiErrors.ExpiredTokenError
          ) {
            this.logout();
            return;
            // This line is commented out because the refreshToken is deprecated in the project
            // return this.handleRefreshToken(request, next);
          }

          if (
            error.status === 401 &&
            error.error.name === ApiErrors.InvalidTokenError
          ) {
            this.logout();
            return;
          }
        }

        return throwError(error);
      })
    );
  }

  private handleRefreshToken(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.refreshTokenInProgress) {
      return this.refreshTokenSubject.pipe(
        filter((result) => result !== null),
        take(1),
        switchMap(() => next.handle(this.addAuthenticationToken(request)))
      );
    }

    this.refreshTokenInProgress = true;

    this.refreshTokenSubject.next(null);

    return this.authenticationService.refreshAccessToken().pipe(
      switchMap(() => {
        this.refreshTokenSubject.next(true);
        return next.handle(this.addAuthenticationToken(request));
      }),
      finalize(() => (this.refreshTokenInProgress = false))
    );
  }

  private isAuthEndpoint(url): boolean {
    return !!this.authEndpoints.find((authEndpoint) =>
      url.includes(authEndpoint)
    );
  }

  private logout() {
    this.authenticationService.logout();
    location.reload();
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    const authToken = this.authenticationService.currentAccessTokenValue;

    if (!authToken) {
      return request;
    }
    if (!request.url.includes(environment.apiUrl)) {
      return request;
    }

    return request.clone({
      setHeaders: {
        Authorization: `${authToken}`,
      },
    });
  }
}
