import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UserEntityType } from '@app/shared/api';
import { environment } from '@environment';
import { Observable, of } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { AuthenticationService } from '../authentication/authentication.service';
import { UserTypes } from '../types/user-types';

@Injectable({ providedIn: 'root' })
export class UserRolesService {
  private roles: string[];
  private userRolesObservable: Observable<string[]>;

  constructor(private http: HttpClient,
              private authenticationService: AuthenticationService
  ) { }

  get user() {
    return this.authenticationService.currentUserValue;
  }

  getUserTypes(): UserTypes {
    const userType = this.user.entityType;
    return {
      user: userType === UserEntityType.User,
      financier: userType === UserEntityType.Financier,
      pharmacy: userType === UserEntityType.Pharmacy,
      doctor: userType === UserEntityType.Doctor,
      beneficiary: userType === UserEntityType.Beneficiary,
      drugCompany: userType === UserEntityType.DrugCompany,
      pharmacyGroup: userType === UserEntityType.PharmacyGroup,
    };
  }

  getRoles(): Observable<string[]> {
    if (this.roles) {
      return of(this.roles);
    } else if (this.userRolesObservable) {
      return this.userRolesObservable;
    }

    this.userRolesObservable = this.http.get<string[]>(`${environment.apiUrl}/users/me/roles`).pipe(
      map(response => {
        this.userRolesObservable = null;
        this.roles = response;
        return this.roles;
      }),
      share()
    );

    return this.userRolesObservable;
  }

  hasRole(role: string) {
    return this.roles.includes(role);
  }

  hasRoles(roles: string[], anyRole = false) {
    if (anyRole) {
      return !!this.roles.find(r => roles.includes(r));
    } else {
      return this.roles.every(r => roles.includes(r));
    }
  }

  getHasRoles(roles: string[], anyRole = false): Observable<boolean> {
    if (anyRole) {
      return this.getRoles().pipe(
        map(userRoles => !!roles.find(role => userRoles.includes(role)))
      );
    } else {
      return this.getRoles().pipe(
        map(userRoles => roles.every(role => userRoles.includes(role)))
      );
    }
  }
}
