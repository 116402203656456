import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PharmacologicalAction } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class PharmacologicalActionApiService extends ResourceEntityApiService<PharmacologicalAction> {
  resourceUrl = 'pharmacological-actions';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
