import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { EntityFile, VademecumItem } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class VademecumItemApiService extends ResourceEntityApiService<VademecumItem> {
  constructor(protected http: HttpClient) {
    super(http);
  }

  protected getCollectionUrl(data?: any, params?: any): string {
    return `${environment.apiUrl}/vademecums/${data?.vademecumId || params.vademecumId}/items`;
  }

  protected getItemUrl(data: any, params?: any): string {
    return `${environment.apiUrl}/vademecums/${data.vademecumId || params.vademecumId}/items/${data.id}`;
  }

  importMedications(vademecumId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<EntityFile>(`${environment.apiUrl}/vademecums/${vademecumId}/import/medications`, formData);
  }

  importMonodrugs(vademecumId, file) {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<EntityFile>(`${environment.apiUrl}/vademecums/${vademecumId}/import/monodrugs`, formData);
  }
}
