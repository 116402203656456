import { DoctorLicense } from './doctor-license';
import { MedicalSpeciality } from './medical-speciality';

export interface DoctorMedicalSpeciality {
  medicalSpecialityId?: number;
  medicalSpeciality?: MedicalSpeciality;
}

export enum DoctorDocumentType {
  DNI = 'DNI',
  CUIL = 'CUIL',
  CI = 'CI',
  LC = 'LC',
  LE = 'LE',
}

export interface Doctor {
  id?: number;
  documentType?: DoctorDocumentType;
  documentNumber?: string;
  lastName?: string;
  firstName?: string;
  fullName?: string;
  sisaValidationDate?: Date;
  sisaUpdateDate?: Date;
  refesCode?: string;
  active?: boolean;
  phone?: string;
  email?: string;
  createdAt?: Date;
  updatedAt?: Date;
  licenses?: DoctorLicense[];
  medicalSpecialities?: DoctorMedicalSpeciality[];
}
