import { Financier } from './financier';
import { Group } from './group';

export enum UserStatus {
  'Active' = 'Active',
  'Inactive' = 'Inactive',
  'Blocked' = 'Blocked',
  'Disabled' = 'Disabled',
}

export enum UserEntityType {
  'User' = 'User',
  'Financier' = 'Financier',
  'Pharmacy' = 'Pharmacy',
  'PharmacyGroup' = 'PharmacyGroup',
  'Doctor' = 'Doctor',
  'Beneficiary' = 'Beneficiary',
  'DrugCompany' = 'DrugCompany',
}

export interface UserAssociatedEntity {
  id?: number;
  entityDisplay?: string;
}

export interface UserAuditRules {
  id?: number;
  financierId?: number;
  coverageProgramId?: number;
}

export interface User {
  id?: number;
  email?: string;
  name?: string;
  description?: string;
  status?: string;
  entityType?: UserEntityType;
  entityId?: number;
  entityDisplay?: string;
  notificationMisValidaciones?: boolean;
  token?: string;
  createdBy?: number;
  createdAt?: Date;
  updatedAt?: Date;
  groups?: Group[];
  financiers?: Financier[];
  associatedEntity?: UserAssociatedEntity;
  auditRules?: UserAuditRules[];

}
