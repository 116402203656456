import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { map, tap } from 'rxjs/operators';
import {
  ApiList,
  Beneficiary,
  BeneficiaryCoverageProgram,
  BeneficiaryPathology,
  BeneficiaryTreatment,
  EntityFile,
  QueryParams,
} from '../models';
import {
  getHttpParams,
  parseUtcDateProperties,
  processApiList,
} from '../utils';
import { EntityFileApiHelperService } from './entity-file-api-helper.service';
import { EntityFileApiService } from './entity-file-api-service.type';
import { ResourceEntityApiService } from './resource-entity-api-service';
import { Observable } from 'rxjs';

@Injectable()
export class BeneficiaryApiService
  extends ResourceEntityApiService<Beneficiary>
  implements EntityFileApiService
{
  resourceUrl = 'beneficiaries';

  constructor(
    protected http: HttpClient,
    protected entityFileApiHelperService: EntityFileApiHelperService
  ) {
    super(http);
  }

  parseEntity(entity) {
    parseUtcDateProperties(entity, ['birthdate']);
    parseUtcDateProperties(entity, ['inactivationDate']);
    parseUtcDateProperties(entity.coveragePrograms, ['startDate', 'endDate']);
    parseUtcDateProperties(entity.diagnosisSummaries, ['date']);
    parseUtcDateProperties(entity.disabilityCertificates, [
      'startDate',
      'endDate',
    ]);
    parseUtcDateProperties(entity.diabetesCertificates, ['certificateDate']);
    return entity;
  }

  getPrescriptionSuggestions(data, params) {
    let httpParams = new HttpParams();
    httpParams = httpParams.set(
      'coverageProgramId',
      params.coverageProgramId.toString()
    );
    return this.http
      .get<any>(`${this.getItemUrl(data, params)}/prescription-suggestions`, {
        params: httpParams,
      })
      .pipe(tap(processApiList));
  }

  getDiagnosesByBeneficiary(queryParams?: QueryParams, beneficiaryId?: any): Observable<ApiList<Beneficiary>> {
    if (beneficiaryId) {
      if (!queryParams) {
        queryParams = {};
      }
      queryParams.filter = `beneficiaryId eq ${beneficiaryId}`;
    }
    return this.http.get<ApiList<BeneficiaryTreatment>>(
      `${environment.apiUrl}/beneficiaries/${beneficiaryId}/diagnoses`,
      { params: getHttpParams(queryParams) }
    ).pipe(
      tap(processApiList),
      map(list => this.parseList(list))
    );
  }

  getPathologyByBeneficiary(queryParams?: QueryParams, beneficiaryId?: any): Observable<ApiList<BeneficiaryPathology>> {
    return this.http.get<ApiList<any>>(
      `${environment.apiUrl}/beneficiaries/${beneficiaryId}/pathologies`,
      { params: getHttpParams(queryParams) }
    ).pipe(
      tap(processApiList),
      map(list => this.parseList(list))
    );
  }

  getDistributionTypeByCoverageProgram(
    beneficiaryId,
    coverageProgramId,
    financierId
  ) {
    return this.http.get<any>(
      `${environment.apiUrl}/beneficiaries/treatment/${beneficiaryId}/${coverageProgramId}/${financierId}`
    );
  }

  getTreatmentsActivesForBeneficiaryMonodrugidPotency(
    beneficiaryId,
    coverageProgramId,
    monodrogId,
    potency
  ) {
    const potencyParsed = potency ? potency.split('/').join('|') : 'null';
    return this.http.get<any>(
      `${environment.apiUrl}/beneficiaries/treatments/beneficiaryId/${beneficiaryId}/coverageProgramId/${coverageProgramId}/monodrugId/${monodrogId}/potency/${potencyParsed}`
    );
  }

  /**
   * @description This method brings a list of dispatch items and prescription items to be able to calculate
   * the consumption of medications according to the beneficiary id and the potency of the medication.
   * @param {string} beneficiaryId beneficiary id
   * @param {string} financierId financier id
   * @param {number} monodrugId monodrug id
   * @param {string} potency potency of the medication
   * @returns {Observable<ApiList<DispatchItem[] | PrescriptionItem[]>>} dispatch items and prescription items list
   */
  getDispatchByBenficiary(
    beneficiaryId: string,
    financierId: string,
    monodrugId: number,
    potency: string
  ) {
    const potencyParsed = potency ? potency.split('/').join('|') : 'null';
    return this.http.get<any>(
      `${environment.apiUrl}/dispatch-items/beneficiaryId/${beneficiaryId}/financierId/${financierId}/monodrugId/${monodrugId}/potency/${potencyParsed}`
    );
  }

  //TODO: Refactor this method(API)
  /**
   * @description This method brings a beneficiary by id, unlike the base method,
   * it brings the list of treatments and their medications
   * @param {number} id beneficiary id
   * @returns {Observable<Benficiary>} beneficiary entity
   */
  getBeneficiayByIdWithMedicationsAndTratements(id: number) {
    return this.http.get<Beneficiary>(
      `${environment.apiUrl}/beneficiaries/${id}/validate`
    );
  }

  getAllFiles(entityParams, queryParams?) {
    return this.entityFileApiHelperService.getAllFiles(
      this,
      entityParams,
      queryParams
    );
  }

  getFileById(entityParams, fileId) {
    return this.entityFileApiHelperService.getFileById(
      this,
      entityParams,
      fileId
    );
  }

  uploadFile(entityParams, files) {
    return this.entityFileApiHelperService.uploadFile(
      this,
      entityParams,
      files
    );
  }

  deleteFile(entityParams, fileId) {
    return this.entityFileApiHelperService.deleteFile(
      this,
      entityParams,
      fileId
    );
  }

  downloadFile(file: EntityFile) {
    this.entityFileApiHelperService.downloadFile(this, file);
  }

  searchBeneficiaries(queryParams?: QueryParams, params?: any): Observable<ApiList<Beneficiary>> {
    return this.http.get<ApiList<Beneficiary>>(
      `${environment.apiUrl}/beneficiariesSearch`,
      { params: getHttpParams(queryParams) }
    ).pipe(
      tap(processApiList),
      map(list => this.parseList(list))
    );
  }
}
