import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable({ providedIn: 'root' })
export class SisaApiService {
  constructor(protected http: HttpClient) {}

  searchProfessionals(license: string, firstName: string, lastName: string) {
    return this.http.get<any>(`${environment.apiUrl}/sisa/professionals/search?license=${license}&firstName=${firstName}&lastName=${lastName}`);
  }

  getProfessional(code: string) {
    return this.http.get<any>(`${environment.apiUrl}/sisa/professionals?code=${code}`);
  }
}
