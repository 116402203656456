export enum DebitCreditReasonsType {
  'Debito' = 'Debito',
  'Credito' = 'Credito',
}

export enum DebitCreditReasonsRebillable {
  'Si' = 'Si',
  'No' = 'No',
}

export interface DebitCreditReasons {
  id: number;
  description?: string;
  type: DebitCreditReasonsType;
  rebillable: DebitCreditReasonsRebillable
  createdAt?: Date;
  updatedAt?: Date;
}
