import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Translations } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { LocaleSettings } from 'primeng/calendar';

@Injectable()
export class I18nService {
  constructor(private translate: TranslateService,
              private lang: Translations) {
  }

  getLang() {
    // TODO: get lang dynamically
    return 'es';
  }

  getLocale() {
    // TODO: get locale dynamically
    return 'es-AR';
  }

  shortDate(date) {
    const pipe = new DatePipe(this.getLocale());
    return pipe.transform(date, 'shortDate');
  }

  getCalendarLocale(): LocaleSettings {
    return {
      dateFormat: this.translate.instant(this.lang.COMMON.CALENDAR.dateFormat),
      firstDayOfWeek: Number(this.translate.instant(this.lang.COMMON.CALENDAR.firstDayOfWeek)),
      dayNames: [
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES.day1),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES.day2),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES.day3),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES.day4),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES.day5),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES.day6),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES.day7),
      ],
      dayNamesShort: [
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES_SHORT.day1),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES_SHORT.day2),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES_SHORT.day3),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES_SHORT.day4),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES_SHORT.day5),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES_SHORT.day6),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES_SHORT.day7),
      ],
      dayNamesMin: [
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES_MIN.day1),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES_MIN.day2),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES_MIN.day3),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES_MIN.day4),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES_MIN.day5),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES_MIN.day6),
        this.translate.instant(this.lang.COMMON.CALENDAR.DAY_NAMES_MIN.day7),
      ],
      monthNames: [
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES.month1),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES.month2),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES.month3),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES.month4),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES.month5),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES.month6),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES.month7),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES.month8),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES.month9),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES.month10),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES.month11),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES.month12),
      ],
      monthNamesShort: [
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES_SHORT.month1),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES_SHORT.month2),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES_SHORT.month3),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES_SHORT.month4),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES_SHORT.month5),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES_SHORT.month6),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES_SHORT.month7),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES_SHORT.month8),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES_SHORT.month9),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES_SHORT.month10),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES_SHORT.month11),
        this.translate.instant(this.lang.COMMON.CALENDAR.MONTH_NAMES_SHORT.month12),
      ],
      today: this.translate.instant(this.lang.COMMON.CALENDAR.today),
      clear: this.translate.instant(this.lang.COMMON.CALENDAR.clear),
    };
  }
}
