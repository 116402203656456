import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import {
  ApiList,
 LogisticData
} from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';



@Injectable()
export class LogisticDataApiService extends ResourceEntityApiService<LogisticData>{

  constructor(protected http: HttpClient,) {
    super(http)
  }

  checkTraceDispatch(prescriptionId: number) {
    return this.http
      .get<ApiList<LogisticData>>(
        `${environment.apiUrl}/distribute/checkTraceDispatch/${prescriptionId}`
      );
  }

}
