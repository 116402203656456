import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '@app/shared/api';
import { environment } from '@environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  private accessTokenSubject: BehaviorSubject<string>;
  public accessToken: Observable<string>;

  private refreshTokenSubject: BehaviorSubject<string>;
  public refreshToken: Observable<string>;

  public redirectUrl: string;

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService
  ) {
    const user = localStorageService.get('currentUser');
    if (!user) {
      this.logout();
    }

    this.currentUserSubject = new BehaviorSubject<User>(user);
    this.currentUser = this.currentUserSubject.asObservable();

    this.accessTokenSubject = new BehaviorSubject<string>(
      localStorageService.get('accessToken')
    );
    this.accessToken = this.accessTokenSubject.asObservable();

    this.refreshTokenSubject = new BehaviorSubject<string>(
      localStorageService.get('refreshToken')
    );
    this.refreshToken = this.refreshTokenSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get currentAccessTokenValue(): string {
    return this.accessTokenSubject.value;
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(
        `${environment.apiUrl}/users/login`,
        { email, password },
        { observe: 'response' }
      )
      .pipe(
        tap((response) => {
          this.setUser(response.body);

          const accessToken = response.headers.get('Authorization');
          this.setAccessToken(accessToken);

          const refreshToken = response.headers.get('x-refresh-token');
          this.setRefreshToken(refreshToken);

          this.setActiveSessionTime();

          return response.body;
        })
      );
  }

  isLoggedIn() {
    return this.currentUserValue && this.currentAccessTokenValue;
  }

  logout() {
    this.setUser(null);
    this.setAccessToken(null);
    this.setRefreshToken(null);
    this.clearActiveSessionTime();
  }

  forgotPassword(email: string) {
    return this.http.post<any>(`${environment.apiUrl}/users/forgot-password`, {
      email,
    });
  }

  getUserByResetPasswordToken(token: string) {
    return this.http.get<any>(
      `${environment.apiUrl}/users/reset-password/${token}`
    );
  }

  resetPassword(token: string, password: string) {
    return this.http.post<any>(`${environment.apiUrl}/users/reset-password`, {
      token,
      password,
    });
  }

  refreshAccessToken() {
    return this.http
      .post<any>(
        `${environment.apiUrl}/users/refresh-token`,
        { token: this.refreshTokenSubject.value },
        { observe: 'response' }
      )
      .pipe(
        tap((response) => {
          this.setUser(response.body);

          const accessToken = response.headers.get('Authorization');
          this.setAccessToken(accessToken);
        })
      );
  }

  setUser(user) {
    if (user) {
      this.localStorageService.set('currentUser', user);
    } else {
      this.localStorageService.remove('currentUser');
    }
    this.currentUserSubject?.next(user);
  }

  setActiveSessionTime() {
    this.localStorageService.set('activeSessionTime', new Date().getTime());
  }
  /* the time expiration is 12 hours */
  getActiveSessionTime() {
    /* this is the time in minutes */
    const expirationTime = 719;
    const initialDate = this.localStorageService.get('activeSessionTime');
    let activeSessionTime = new Date().getTime() - initialDate;
    activeSessionTime = Math.round(activeSessionTime / 1000 / 60);
    if (activeSessionTime > expirationTime) {
      this.logout();
      location.reload();
    }
  }
  clearActiveSessionTime() {
    this.localStorageService.remove('activeSessionTime');
  }

  setAccessToken(accessToken) {
    if (accessToken) {
      this.localStorageService.set('accessToken', accessToken);
    } else {
      this.localStorageService.remove('accessToken');
    }
    this.accessTokenSubject?.next(accessToken);
  }

  setRefreshToken(refreshToken) {
    if (refreshToken) {
      this.localStorageService.set('refreshToken', refreshToken);
    } else {
      this.localStorageService.remove('refreshToken');
    }
    this.refreshTokenSubject?.next(refreshToken);
  }
}
