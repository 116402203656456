import { Injectable } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Injectable()
export class CommonMessageService {
  constructor(private messageService: MessageService) {}

  success(message: string | Message) {
    this.addMessage(message, 'success');
  }

  info(message: string | Message) {
    this.addMessage(message, 'info');
  }

  warn(message: string | Message) {
    this.addMessage(message, 'warn');
  }

  error(message: string | Message) {
    this.addMessage(message, 'error');
  }

  addMessage(message: string | Message, severity: string) {
    if (typeof message === 'string') {
      message = { detail: message };
    }
    if (message.id && message.id === 'pharmacyBatchesGenerated') {
      message = { detail: message.detail , life: 10000};
    }
    this.messageService.add({
      key: 'app',
      severity,
      summary: null,
      ...message,
    });
  }
}
