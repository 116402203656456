import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReportView } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class ReportsViewApiService extends ResourceEntityApiService<ReportView> {
  resourceUrl = 'reports/views';

  constructor(protected http: HttpClient) {
    super(http);
  }

  getViews() {
    return this.http.get<ReportView>(`${this.getCollectionUrl()}`);
  }

  getViewDetail(viewName: string) {
    return this.http.get<any>(`${this.getCollectionUrl()}/${viewName}`);
  }
}
