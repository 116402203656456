import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Message, MessageService } from 'primeng/api';
import { Translations } from '../utils/translations';
import { ApiErrors } from './api-errors';

@Injectable({ providedIn: 'root' })
export class ErrorService {
  public readonly api = ApiErrors;

  constructor(private messageService: MessageService,
              private zone: NgZone,
              private readonly lang: Translations,
              private translate: TranslateService) {}

  handleError(error: Error | HttpErrorResponse, messageOptions = {}) {
    let message;
    if (error instanceof HttpErrorResponse) {
      message = this.getServerMessage(error);
      console.error('Server Error', error);
    } else {
      message = this.getClientMessage(error);
      console.error('Client Error', error);
    }
    this.showError(Object.assign(message, messageOptions));
  }

  showError(message: Message) {
    message.key = message.key || 'app';
    message.severity = message.severity || 'error';
    message.summary = message.summary || 'Error';
    message.life = message.life || 6000;

    this.zone.run(() => {
      this.messageService.add(message);
    });
  }

  getClientMessage(error: Error): Message {
    if (!navigator.onLine) {
      return {
        detail: this.translate.instant(this.lang.ERRORS.noInternetConnection),
        sticky: true,
      };
    }
    return {
      summary: error?.name || this.translate.instant(this.lang.ERRORS.unknown),
      detail: error?.message,
      data: {
        type: 'ClientError',
        hasDetail: true,
        error,
        errorPretty: JSON.stringify({
          name: error?.name,
          message: error?.message,
          stack: error?.stack,
        }, null, 2).replace(/\\n/g, '\n'),
      },
      sticky: true,
    };
  }

  getServerMessage(error: HttpErrorResponse): Message {
    if (!navigator.onLine) {
      return {
        detail: this.translate.instant(this.lang.ERRORS.noInternetConnection),
        sticky: true,
      };
    }
    if (!error.status) {
      return {
        detail: this.translate.instant(this.lang.ERRORS.cantReachServer),
        life: 10000,
      };
    }

    return {
      summary: this.translate.instant(this.lang.ERRORS.unhandledServerError),
      detail: error?.error?.message,
      data: {
        type: 'ServerError',
        hasDetail: true,
        error,
        errorPretty: JSON.stringify(error, null, 2).replace(/\\n/g, '\n'),
      },
      sticky: true,
    };
  }

  getUiErrorMessage(error: string, params?: object): Message {
    return {
      key: 'app',
      severity: 'error',
      /*summary: this.translate.instant(this.lang.COMMON.error),*/
      detail: this.translate.instant(error, params)
    };
  }
}
