import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { tap } from 'rxjs/operators';
import { ApiList, Locality, ZoneLocality } from '../models';
import { getHttpParams, processApiList } from '../utils';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class ZoneLocalityApiService extends ResourceEntityApiService<ZoneLocality> {
  constructor(protected http: HttpClient) {
    super(http);
  }

  protected getCollectionUrl(data?: any, params?: any): string {
    return `${environment.apiUrl}/zones/${data?.zoneId || params.zoneId}/localities`;
  }

  protected getItemUrl(data: any, params?: any): string {
    return `${environment.apiUrl}/zones/${data.zoneId || params.zoneId}/localities/${data.id}`;
  }
}
