import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountableDocumentApiService } from './accountable-document-api.service';

@Injectable()
export class ExecutedRefundingDocumentApiService extends AccountableDocumentApiService {
  getAllUrl = 'executed-refunding-documents';

  constructor(protected http: HttpClient) {
    super(http);
  }



}
