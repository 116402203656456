import { Component } from '@angular/core';
import { Translations } from '@app/core';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
})
export class NotFoundComponent {
  constructor(public readonly lang: Translations) {}
}
