import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { randomId, Translations } from '@app/core';

@Component({
  selector: 'app-shared-delete-dialog',
  templateUrl: './confirm-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmDialogComponent {
  @Input() key;

  constructor(public readonly lang: Translations) {
    this.key = randomId();
  }
}
