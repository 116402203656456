import { Address } from './address';
import { CoverageProgram } from './coverage-program';
import { DrugCompany, DrugCompanyBusinessType } from './drug-company';
import { Financier } from './financier';
import { PharmacyAgreementRule } from './pharmacy-agreement-rule';
import { PharmacyGroup } from './pharmacy-group';
import { Vademecum } from './vademecum';

export interface PharmacyDrugCompany {
  id: number;
  pharmacyId: number;
  drugCompanyId: number;
  clientCode: string;
  branch: string;
  division: string;
  drugCompany: DrugCompany;
}
export interface PharmacyContact {
  id?: number;
  beneficiaryId?: number;
  name?: string;
  label?: string;
  phone?: string;
  email?: string;
}

export enum PharmacyPaymentMethod {
  'Transfer' = 'Transfer',
  'Check' = 'Check',
  'DrugCompany' = 'DrugCompany',
}

export enum PharmacyPaymentDeadline {
  'Deadline0' = '0',
  'Deadline21' = '21',
  'Deadline30' = '30',
  'Deadline45' = '45',
  'Deadline60' = '60',
  'Deadline90' = '90',
}

export enum PharmacyPaymentPriority {
  'NonSpecified' = 'NonSpecified',
  'Week1' = 'Week1',
  'Week2' = 'Week2',
  'Week3' = 'Week3',
  'Week4' = 'Week4',
}

export interface PharmacyPharmacyGroup {
  id: number;
  cuit?: string;
  businessName?: string;
}

export interface Pharmacy {
  id: number;
  cuit?: string;
  gln?: string;
  cuf?: string;
  businessName?: string;
  shortName?: string;
  address?: Address;
  active?: boolean;
  startDate?: Date;
  endDate?: Date;
  openingHours?: string;
  observations?: string;
  pharmacyGroupId?: number;
  debitShippingAddress?: Address;
  open24hs?: boolean;
  homeDelivery?: boolean;
  redispense?: boolean;
  paymentMethod?: PharmacyPaymentMethod;
  paymentDeadline?: PharmacyPaymentDeadline;
  paymentPriority?: PharmacyPaymentPriority;
  paymentDrugCompanyId?: number;
  codeERP?: string;
  paymentEmail?: string;
  codeAdesfa?: string;
  codeFarmalink?: string;
  codePAMI?: string;
  codeMisValidaciones?: string;
  disabledPreserfar?: boolean;
  drugCompanies?: PharmacyDrugCompany[];
  agreementRules?: PharmacyAgreementRule[];
  contacts?: PharmacyContact[];
  createdAt?: Date;
  updatedAt?: Date;
  pharmacyGroup?: PharmacyPharmacyGroup[];
  drugCompany?: DrugCompany;
}
