export class Report {
  recipe: string;
  parameters: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export class ReportTemplate {
  id?: number;
  name?: string;
  content?: string;
  helpers?: string;
  recipe?: string;
  query?: string;
  queryParameters?: string;
  dsDataProvider?: string;
  dsModel?: string;
  dsType?: string;
  serializer?: string;
  responseType?: ReportResponseType;
  columns?: string;
  view?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export class ReportView {
  data: Array<any>;
}

export enum ReportResponseType {
  // 'PDF' = 'Pdf',
  'CSV' = 'Csv',
  'HTML' = 'Html'
}

export interface ReportFilter {
  column?: string;
  operation?: string;
  value?: string;
}
