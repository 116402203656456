import { AdministrationRoute } from './administration-route';
import { Beneficiary } from './beneficiary';
import { CoverageProgram } from './coverage-program';
import { Financier } from './financier';
import { Medication } from './medication';
import { Monodrug } from './monodrug';
import { VademecumItem } from './vademecum';
export enum PrescriptionItemPeriod {
  'every6Hours' = 'every6Hours',
  'every8Hours' = 'every8Hours',
  'every12Hours' = 'every12Hours',
  'every24Hours' = 'every24Hours',
  'otherDay' = 'otherDay',
  'weekly' = 'weekly',
  'biweekly' = 'biweekly',
  'monthly' = 'monthly',
  'bimonthly' = 'bimonthly',
  'quarterly' = 'quarterly',
  'biannual' = 'biannual',
  'annual' = 'annual',
}

export enum PrescriptionItemStatus {
  'Loaded' = 'Loaded',
  'Authorized' = 'Authorized',
  'Rejected' = 'Rejected',
  'AutomaticallyAudited' = 'AutomaticallyAudited',
  'Audited' = 'Audited',
  'Requested' = 'Requested',
  'Shipped' = 'Shipped',
  'InPharmacy' = 'InPharmacy',
  'PartiallyDispensed' = 'PartiallyDispensed',
  'Dispensed' = 'Dispensed',
  'Returned' = 'Returned',
  'Canceled' = 'Canceled',
}

export enum PrescriptionItemContractType {
  'Capita' = 'Capita',
  'Provision' = 'Provision',
}

export enum DistributionStatus {
  'OutOfStock' = 'OutOfStock',
  'Missing' = 'Missing',
  'Purchase' = 'Purchase',
  'Transfer' = 'Transfer'
}

export interface PrescriptionItem {
  id?: number;
  prescriptionId?: number;
  originalItemId?: number;
  partitionFromItemId?: number;
  monodrugId?: number;
  medicationId?: number;
  dosageFormId?: number;
  administrationRouteId?: number;
  potency: string;
  coverageProgramId?: number;
  contractType?: PrescriptionItemContractType;
  costCenter?: string;
  diagnosisId?: number;
  diagnosis?: string;
  status?: PrescriptionItemStatus;
  observations?: string;
  dosage?: number;
  period?: PrescriptionItemPeriod;
  traceable?: boolean;
  days?: number;
  prescribesBrand?: boolean;
  prescribesBrandReason?: string;
  prescribedBrandOnly?: boolean;
  prescribedAmount?: number;
  annualConsumption?: number;
  authorizedAmount?: number;
  issuedAmount?: number;
  amount?: number;
  financierCoverage?: number;
  beneficiaryCoverage?: number;
  billedPercent?: number;
  specialCoverage?: boolean;
  misValidacionesPrescriptionId?: number;
  codeMisValidaciones?: string;
  distributionOrderId?: number;
  distributionOrderDate?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  prescription?: any;
  monodrug?: Monodrug;
  billingPrice?: any;
  medication?: Medication;
  administrationRoute?: AdministrationRoute;
  coverageProgram?: CoverageProgram;
  beneficiary?: Beneficiary;
  financier?: Financier;
  beneficiaryUnitPrice?: number;
  vademecumItems: VademecumItem[];
  authorizedUserId?: number;
  itemCoverage?: number;
  receiptNumber?: string;
  nextDeliveryDate?: Date;
  distributionStatus?: DistributionStatus;
  estimatedEntryDate?: Date;
}
