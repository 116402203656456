import { Injectable } from '@angular/core';
import { Translations } from '@app/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Observable } from 'rxjs';

@Injectable()
export class CommonDialogService {
  constructor(
    private confirmationService: ConfirmationService,
    private translate: TranslateService,
    private lang: Translations) {
  }

  confirm(options): Observable<any> {
    return new Observable(observer => {
      setTimeout(() => {
        this.confirmationService.confirm({
          key: 'app',
          acceptIcon: 'ui-icon-done',
          acceptLabel: this.translate.instant(this.lang.COMMON.accept),
          rejectIcon: 'ui-icon-close',
          rejectLabel: this.translate.instant(this.lang.COMMON.cancel),
          rejectButtonStyleClass: 'p-button-text',
          accept: () => {
            setTimeout(() => {
              observer.next(true);
              observer.complete();
            }, 450);
          },
          reject: () => {
            setTimeout(() => {
              observer.next(false);
              observer.complete();
            }, 450);
          },
          ...options
        });
      });
    });
  }

  confirmDelete(item, display?: string, key = 'app'): Observable<any> {
    // FIXME: interpolate name
    // display = typeof display === 'string' ? display : item.name || item.businessName || '';

    return new Observable(observer => {
      setTimeout(() => {
        this.confirmationService.confirm({
          key,
          header: this.translate.instant(this.lang.SHARED.COMPONENTS.DELETE_DIALOG.header, { item: display }),
          message: this.translate.instant(this.lang.SHARED.COMPONENTS.DELETE_DIALOG.message),
          acceptLabel: this.translate.instant(this.lang.SHARED.COMPONENTS.DELETE_DIALOG.accept),
          acceptIcon: 'ui-icon-delete',
          acceptButtonStyleClass: 'p-button-danger',
          rejectLabel: this.translate.instant(this.lang.SHARED.COMPONENTS.DELETE_DIALOG.reject),
          rejectButtonStyleClass: 'p-button-text',
          accept: () => {
            observer.next(item);
            observer.complete();
          },
          reject: () => {
            observer.complete();
          },
        });
      });
    });
  }

  confirmDeleteMultiple(data, key = 'app'): Observable<any> {
    return new Observable(observer => {
      setTimeout(() => {
        this.confirmationService.confirm({
          key,
          header: this.translate.instant(this.lang.SHARED.COMPONENTS.DELETE_DIALOG.headerMultiple),
          message: this.translate.instant(this.lang.SHARED.COMPONENTS.DELETE_DIALOG.messageMultiple),
          acceptLabel: this.translate.instant(this.lang.SHARED.COMPONENTS.DELETE_DIALOG.accept),
          acceptIcon: 'ui-icon-delete',
          acceptButtonStyleClass: 'p-button-danger',
          rejectLabel: this.translate.instant(this.lang.SHARED.COMPONENTS.DELETE_DIALOG.reject),
          rejectButtonStyleClass: 'p-button-text',
          accept: () => {
            observer.next(data);
            observer.complete();
          },
          reject: () => {
            observer.complete();
          },
        });
      });
    });
  }
}
