import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { KeyFilterModule } from 'primeng/keyfilter';
import { ListboxModule } from 'primeng/listbox';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import {
  CalendarComponent,
  ConfirmDialogComponent,
  ErrorDetailsComponent,
  FileUploadComponent,
  PageMenuComponent,
  LoadingDialogComponent,
} from './components';
import {
  CommonDialogService,
  CommonMessageService,
  I18nService,
  PrimeNGCorrectionService,
  UtilsService
} from './services';

const COMMON_IMPORTS = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,

  AutoCompleteModule,
  ButtonModule,
  CalendarModule,
  CardModule,
  CheckboxModule,
  ConfirmDialogModule,
  DialogModule,
  DynamicDialogModule,
  DropdownModule,
  FileUploadModule,
  InputMaskModule,
  InputNumberModule,
  InputSwitchModule,
  InputTextModule,
  InputTextareaModule,
  KeyFilterModule,
  ListboxModule,
  MenuModule,
  MenubarModule,
  MessageModule,
  MessagesModule,
  MultiSelectModule,
  OverlayPanelModule,
  PanelModule,
  ProgressSpinnerModule,
  RadioButtonModule,
  RippleModule,
  ScrollPanelModule,
  SelectButtonModule,
  SliderModule,
  SpinnerModule,
  TableModule,
  TabMenuModule,
  TabViewModule,
  ToastModule,
  ToggleButtonModule,
  ToolbarModule,
  TreeModule,
  TriStateCheckboxModule,
  TooltipModule,
];

const MODULE_DECLARATIONS = [
  CalendarComponent,
  ConfirmDialogComponent,
  ErrorDetailsComponent,
  FileUploadComponent,
  PageMenuComponent,
  LoadingDialogComponent,
];

@NgModule({
  declarations: MODULE_DECLARATIONS,
  exports: [
    ...MODULE_DECLARATIONS,
    ...COMMON_IMPORTS
  ],
  imports: [
    TranslateModule.forChild(),
    RouterModule,
    ...COMMON_IMPORTS,
  ],
  providers: [
    I18nService,
    CommonDialogService,
    CommonMessageService,
    PrimeNGCorrectionService,
    UtilsService,
  ]
})
export class SharedCommonModule {}
