import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SAPDistributionRule } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class SAPDistributionRuleApiService extends ResourceEntityApiService<SAPDistributionRule> {
  resourceUrl = 'sap-distribution-rules';

  constructor(protected http: HttpClient) {
    super(http);
  }

  getPercents() {
    return this.http.get<any>(`${this.getCollectionUrl()}/percents`);
  }
}
