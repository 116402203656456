import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DebitCreditReasons } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class DebitCreditReasonsApiService extends ResourceEntityApiService<DebitCreditReasons> {
  resourceUrl = 'debit-credit-reasons';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
