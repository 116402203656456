import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PrescriptionItemLog } from '@app/shared/api/models/prescription-item-log';
import { environment } from '@environment';
import { map, tap } from 'rxjs/operators';
import { ApiList, PrescriptionItem, QueryParams } from '../models';
import { getHttpParams, parseUtcDate, parseUtcDateProperties, processApiList } from '../utils';
import { ResourceEntityApiService } from './resource-entity-api-service';


@Injectable()
export class PrescriptionItemApiService extends ResourceEntityApiService<PrescriptionItem> {
  resourceUrl = 'prescription-items';

  constructor(protected http: HttpClient) {
    super(http);
  }

  parseEntity(entity) {
    parseUtcDateProperties(entity, ['date']);
    parseUtcDateProperties(entity, ['provisionLimitDate']);
    return entity;
  }

  audit(data) {
    return this.http.post<any>(`${environment.apiUrl}/prescription-items/audit`, data);
  }

  receive(data) {
    return this.http.put<any>(`${environment.apiUrl}/prescription-items/receive`, data);
  }

  infoDrugCompany(data: any, prescriptionItem: any){
    return this.http.put<any>(`${environment.apiUrl}/prescription-items/${prescriptionItem}/infoDrugCompany`, data)
    .pipe(map((entity) => entity && this.parseEntity({ ...entity })));
  }

  getLogs(prescriptionItemId: number, queryParams?: QueryParams) {
    return this.http.get<ApiList<PrescriptionItemLog>>(
      `${this.getItemUrl({ id: prescriptionItemId })}/logs`,
      { params: getHttpParams(queryParams) }
    ).pipe(tap(processApiList));
  }

  searchByBeneficiary(beneficiaryId, action, queryParams) {
    return this.http.get<ApiList<PrescriptionItem>>(
      `${environment.apiUrl}/beneficiaries/${beneficiaryId}/prescription-items/${action}`,
      { params: getHttpParams(queryParams) }
    ).pipe(tap(processApiList));
  }

  getAllToAudit(queryParams, currentUserId) {
    return this.http.get<ApiList<PrescriptionItem>>(
      `${environment.apiUrl}/prescription-items/audit/${currentUserId}`,
      { params: getHttpParams(queryParams) }
    ).pipe(tap(processApiList));
  }

  return(data) {
    return this.http.put<any>(`${environment.apiUrl}/prescription-items/return`, data);
  }

  cancel(data) {
    return this.http.post<any>(`${environment.apiUrl}/prescription-items/${data.id}/cancel`, data);
  }

  getStatus(prescriptionItemId){

    return this.http.get<any>(`${environment.apiUrl}/prescription-items/${prescriptionItemId}/status`);
  }

  
}
