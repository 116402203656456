import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { User } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class UserApiService extends ResourceEntityApiService<User> {
  resourceUrl = 'users';

  constructor(protected http: HttpClient) {
    super(http);
  }

  parseEntity(entity) {
    if (entity.entityId && entity.entityDisplay) {
      entity.associatedEntity = {
        id: entity.entityId,
        entityDisplay: entity.entityDisplay,
      };
    }
    return entity;
  }

  changePassword(oldPassword: string, newPassword: string) {
    const baseUrl = this.getItemUrl({ id: 'me' });
    return this.http.post<any>(`${baseUrl}/change-password`, { oldPassword, newPassword });
  }
}
