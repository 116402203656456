import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PharmacyAgreementRule } from '../models';
import { EntityFileApiHelperService } from './entity-file-api-helper.service';
import { ResourceEntityApiService } from './resource-entity-api-service';
import { environment } from '@environment';

@Injectable()
export class PharmacyAgreementRuleApiService extends ResourceEntityApiService<PharmacyAgreementRule> {
  constructor(protected http: HttpClient,
              protected entityFileApiHelperService: EntityFileApiHelperService) {
    super(http);
  }

  protected getCollectionUrl(data?: any, params?: any): string {
    return `${environment.apiUrl}/pharmacies/${data?.pharmacyId || params.pharmacyId}/agreement-rules`;
  }

  protected getItemUrl(data: any, params?: any): string {
    return `${environment.apiUrl}/pharmacies/${data.pharmacyId || params.pharmacyId}/agreement-rules/${data.id}`;
  }
}
