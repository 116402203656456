import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AppTitleService {
  appTitle: string;

  constructor(private titleService: Title,
              private translate: TranslateService) {
    this.appTitle = this.titleService.getTitle();
  }

  setTitle(title: string) {
    this.titleService.setTitle(`${title} | ${this.appTitle}`);
  }

  setLangKeyTitle(titleLangKey: string, params?: any) {
    if (!titleLangKey) {
      console.warn('Missing title lang key');
      return;
    }
    const title = this.translate.instant(titleLangKey, params);
    this.setTitle(title);
  }
}
