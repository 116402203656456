import { DebitCreditReasons, PharmacyBatch, PharmacyGroup } from '@app/shared/api';
import { InvoiceType } from './invoice-type';
import { Pharmacy, PharmacyPaymentMethod } from './pharmacy';

export enum LiquidationStatus {
  'Generated' = 'Generated',
  'Processed' = 'Processed',
  'Informed' = 'Informed',
  'Paid' = 'Paid',
}

export interface LiquidationAdjustment {
  id: number;
  debitCreditReasonId: number,
  debitCreditReason?: DebitCreditReasons,
  detail: string;
  amount: number;
}

export interface Liquidation {
  id?: number;
  pharmacyId?: number;
  pharmacyGroupId?: number;
  invoiceTypeId?: number;
  invoiceNumber?: string;
  invoiceDate?: Date;
  invoiceAmount?: number;
  receptionDate?: Date;
  liquidatedAmount?: number;
  debitAmount?: number;
  debitedAmount?: number;
  paymentDate?: Date;
  expirationDate: Date;
  paymentMethod?: PharmacyPaymentMethod
  items?: PharmacyBatch[];
  createdAt?: Date;
  updatedAt?: Date;
  pharmacy?: Pharmacy;
  pharmacyGroup?: PharmacyGroup;
  invoiceType?: InvoiceType;
  period?: string;
  status: LiquidationStatus;
  adjustments?: LiquidationAdjustment[];
  observations: string;
  mailNotificationSent?: boolean;
}
