import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PaybackEntity } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class PaybackEntityApiService extends ResourceEntityApiService<PaybackEntity> {
  resourceUrl = 'payback-entities';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
