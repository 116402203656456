import { Address } from './address';
import { DrugCompany } from './drug-company';
import { Pharmacy } from './pharmacy';

export interface PharmacyGroupBankAccount {
  id: number;
  pharmacyGroupId: number;
  bankName: string;
  branch: string;
  accountType: string;
  accountNumber: string;
  accountHolder: string;
  cbu: string;
  cbuAlias: string;
}

export interface PharmacyGroupDrugCompany {
  id: number;
  pharmacyGroupId: number;
  drugCompanyId: number;
  clientCode: string;
  branch: string;
  division: string;
  drugCompany: DrugCompany;
}

export interface PharmacyGroupContact {
  id?: number;
  beneficiaryId?: number;
  name?: string;
  label?: string;
  phone?: string;
  email?: string;
}

export enum PharmacyGroupPaymentMethod {
  'Transfer' = 'Transfer',
  'Check' = 'Check',
  'DrugCompany' = 'DrugCompany',
}

export enum PharmacyGroupPaymentDeadline {
  'Deadline0' = '0',
  'Deadline21' = '21',
  'Deadline30' = '30',
  'Deadline45' = '45',
  'Deadline60' = '60',
  'Deadline90' = '90',
}

export enum PharmacyGroupPaymentPriority {
  'NonSpecified' = 'NonSpecified',
  'Week1' = 'Week1',
  'Week2' = 'Week2',
  'Week3' = 'Week3',
  'Week4' = 'Week4',
}

export interface PharmacyGroup {
  id: number;
  paymentDrugCompanyId?: number;
  cuit?: string;
  businessName?: string;
  address?: Address;
  active?: boolean;
  paymentMethod?: PharmacyGroupPaymentMethod;
  paymentDeadline?: PharmacyGroupPaymentDeadline;
  paymentPriority?: PharmacyGroupPaymentPriority;
  codeERP?: string;
  paymentEmail?: string;
  pharmacies?: any[];
  contacts?: PharmacyGroupContact[];
  bankAccounts?: PharmacyGroupBankAccount[];
  drugCompanies?: PharmacyGroupDrugCompany[];
  drugCompany?: DrugCompany;
  centralizeDebits?: boolean;
  observations?: string
}
