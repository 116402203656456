import { Beneficiary } from './beneficiary';
import { InsulineType } from './insuline-type';

export enum BeneficiaryDiabetesCertificateType {
  'Type I' = 'Type I',
  'Type II' = 'Type II',
  'Gestational Diabetes' = 'Gestational Diabetes',
  'Labile Diabetes' = 'Labile Diabetes'
}

export enum BeneficiaryDiabetesCertificateAlbuminToCreatinineRatio {
  'No data' = 'No data',
  'Normal' = 'Normal',
  'Patologic' = 'Patologic',
}

export enum BeneficiaryDiabetesCertificateFunduscopy {
  'No data' = 'No data',
  'Normal' = 'Normal',
  'Diabetic Retinopathy' = 'Diabetic Retinopathy',
}

export interface BeneficiaryDiabetesCertificate {
  id?: number;
  beneficiaryId?: number;
  type?: BeneficiaryDiabetesCertificateType;
  certificateDate?: Date;
  age?: number;
  dysplemia?: boolean;
  obesity?: boolean;
  smoking?: boolean;
  ventricularHypertrophy?: boolean;
  ventricularHypertrophyDate?: Date;
  myocardialInfarction?: boolean;
  myocardialInfarctionDate?: Date;
  heartFailure?: boolean;
  heartFailureDate?: Date;
  CVA?: boolean;
  CVADate?: Date;
  retinopathy?: boolean;
  retinopathyDate?: Date;
  blindness?: boolean;
  blindnessDate?: Date;
  peripheralNeuropathy?: boolean;
  peripheralNeuropathyDate?: Date;
  PVD?: boolean;
  PVDDate?: Date;
  amputation?: boolean;
  amputationDate?: Date;
  kidneyDisease?: boolean;
  kidneyDiseaseDate?: Date;
  dialysis?: boolean;
  dialysisDate?: Date;
  kidneyTransplant?: boolean;
  kidneyTransplantDate?: Date;
  bloodGlucose?: number;
  bloodGlucoseDate?: Date;
  HBA1C?: number;
  HBA1CDate?: Date;
  LDLColesterol?: number;
  LDLColesterolDate?: Date;
  triglycerides?: number;
  triglyceridesDate?: Date;
  albuminToCreatinineRatio?: BeneficiaryDiabetesCertificateAlbuminToCreatinineRatio;
  albuminToCreatinineRatioDate?: Date;
  systolicBP?: number;
  systolicBPDate?: Date;
  diastolicBP?: number;
  diastolicBPDate?: Date;
  serumCreatinine?: number;
  serumCreatinineDate?: Date;
  funduscopy?: BeneficiaryDiabetesCertificateFunduscopy;
  funduscopyDate?: Date;
  weight?: number;
  weightDate?: Date;
  height?: number;
  heightDate?: Date;
  waistCircumference?: number;
  waistCircumferenceDate?: Date;
  SMBG?: boolean;
  physicalActivity?: boolean;
  treatmentAdherence?: boolean;
  antihypertensives?: boolean;
  lipidloweringMedication?: boolean;
  acetylsalicylicAcid?: boolean;
  hypoglycemicMedication?: boolean;
  basalInsulineTypeId?: number;
  correctionInsulineTypeId?: number;
  createdAt?: Date;
  updatedAt?: Date;
  beneficiary?: Beneficiary;
  basalInsulineType?: InsulineType;
  correctionInsulineType?: InsulineType;
}
