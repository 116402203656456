import { AbstractControl } from '@angular/forms';

export const validPassword = (control: AbstractControl): { [key: string]: any } | null => {
  if (isValidPassword(control.value)) {
    return null;
  }
  return {
    passwordFormat: true
  };
};

export const isValidPassword = (password: string): boolean => {
  if (!password || password.length < 6) {
    return false;
  }

  const specialCharactersRegexp = `\\${' !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'.split('').join('\\')}`;
  let passwordFormatValidation = 0;
  passwordFormatValidation += +!password.match(new RegExp(`^(?=.*[a-z])[a-zA-Z0-9${specialCharactersRegexp}]{6,}$`));
  passwordFormatValidation += +!password.match(new RegExp(`^(?=.*[A-Z])[a-zA-Z0-9${specialCharactersRegexp}]{6,}$`));
  passwordFormatValidation += +!password.match(new RegExp(`^(?=.*[0-9])[a-zA-Z0-9${specialCharactersRegexp}]{6,}$`));
  passwordFormatValidation += +!password.match(new RegExp(`^(?=.*[${specialCharactersRegexp}])[a-zA-Z0-9${specialCharactersRegexp}]{6,}$`));

  return passwordFormatValidation <= 1;
};
