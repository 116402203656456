import { Address } from './address';
import { CoverageProgram } from './coverage-program';
import { DrugCompany, DrugCompanyBusinessType } from './drug-company';
import { Financier } from './financier';
import { PharmacyGroup } from './pharmacy-group';
import { Vademecum } from './vademecum';

export enum PharmacyAgreementRuleDistributionType {
  'Dispensation' = 'Dispensation',
  'DrugCompany' = 'DrugCompany',
}

export enum PharmacyAgreementRuleLiquidateMethod {
  'MerchandisePercent' = 'MerchandisePercent',
  'ServiceFixedAmount' = 'ServiceFixedAmount',
  'ServicePercent' = 'ServicePercent',
}

export interface PharmacyAgreementRule {
  id: number;
  pharmacyId: number;
  financierId: number;
  coverageProgramId: number;
  vademecumId: number;
  distributionType: PharmacyAgreementRuleDistributionType;
  drugCompanyId: number;
  drugCompanyBusinessTypeId: number;
  liquidateMethod: PharmacyAgreementRuleLiquidateMethod;
  value: number;
  byPharmacyGroup: boolean;
  pharmacyGroupId: number;
  financier: Financier;
  coverageProgram: CoverageProgram;
  vademecum: Vademecum;
  drugCompany: DrugCompany;
  drugCompanyBusinessType: DrugCompanyBusinessType;
  pharmacyGroup: PharmacyGroup;
}