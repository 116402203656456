<p-toast #appToast key="app" [baseZIndex]="1000000000">
  <ng-template let-message pTemplate="message">
    <span
      class="p-toast-message-icon pi"
      [ngClass]="{
        'pi-info-circle': message.severity == 'info',
        'pi-exclamation-triangle': message.severity == 'warn',
        'pi-times-circle': message.severity == 'error',
        'pi-check' :message.severity == 'success'
      }"
    ></span>
    <div class="p-toast-message-text">
      <div class="p-toast-summary">{{message.summary}}</div>
      <div class="p-toast-detail">{{message.detail}}</div>
      <div
        *ngIf="message?.data?.hasDetail"
        class="p-toast-detail"
        style="text-align: right"
      >
        <button type="button" pButton pRipple
                class="p-button-text"
                [ngClass]="{
                  'p-button-info': message.severity == 'info',
                  'p-button-warning': message.severity == 'warn',
                  'p-button-danger': message.severity == 'error',
                  'p-button-success' :message.severity == 'success'
                }"
                (click)="showErrorDetail(message)"
                [label]="lang.COMMON.showDetails | translate"
        ></button>
      </div>
    </div>
  </ng-template>
</p-toast>
<ngx-loading-bar color="var(--accentColor)" [includeSpinner]="false"></ngx-loading-bar>
<router-outlet></router-outlet>
