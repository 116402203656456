import { HttpClient } from '@angular/common/http';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiList, QueryParams } from '../models';
import { getHttpParams, processApiList } from '../utils';
import { EntityApiService } from './entity-api-service.type';

export abstract class ResourceEntityApiService<T> implements EntityApiService<T> {
  entityIdField = 'id';
  resourceUrl: string;
  getAllUrl: string;

  constructor(protected http: HttpClient) {}

  protected getCollectionUrl(data?: any, params?: any): string {
    return `${environment.apiUrl}/${this.resourceUrl}`;
  }

  protected getItemUrl(data: any, params?: any): string {
    const entityId = data[this.entityIdField] || params[this.entityIdField];
    return `${environment.apiUrl}/${this.resourceUrl}/${entityId}`;
  }

  getAll(queryParams?: QueryParams, params?: any): Observable<ApiList<T>> {
    return this.http.get<ApiList<T>>(
      this.getAllUrl ? `${this.getCollectionUrl(null, params)}/${this.getAllUrl}` : this.getCollectionUrl(null, params),
      { params: getHttpParams(queryParams) }
    ).pipe(
      tap(processApiList),
      map(list => this.parseList(list))
    );
  }

  getById(data: any, params?: any): Observable<T> {
    return this.http.get<T>(this.getItemUrl(data, params)).pipe(
      map(entity => this.parseEntity({ ...entity }))
    );
  }

  create(data: any, params?: any): Observable<T> {
    return this.http.post<T>(this.getCollectionUrl(data, params), data).pipe(
      map(entity => this.parseEntity({ ...entity }))
    );
  }

  update(data: any, params?: any): Observable<T> {
    return this.http.put<T>(this.getItemUrl(data, params), data).pipe(
      map(entity => entity && this.parseEntity({ ...entity }))
    );
  }

  delete(data: any, params?: any): Observable<object> {
    return this.http.delete(this.getItemUrl(data, params));
  }

  parseList(list: ApiList<any>): ApiList<T> {
    return {
      ...list,
      data: list.data.map(entity => this.parseEntity({ ...entity }))
    };
  }

  parseEntity(entity: any): T {
    return entity;
  }
}
