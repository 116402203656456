import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityFile, PharmacyGroup } from '../models';
import { EntityFileApiHelperService } from './entity-file-api-helper.service';
import { EntityFileApiService } from './entity-file-api-service.type';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class PharmacyGroupApiService extends ResourceEntityApiService<PharmacyGroup> implements EntityFileApiService {
  resourceUrl = 'pharmacy-groups';

  constructor(protected http: HttpClient,
              protected entityFileApiHelperService: EntityFileApiHelperService) {
    super(http);
  }

  parseEntity(entity) {
    if (entity.pharmacies) {
      entity.pharmacies = entity.pharmacies.map(p => ({
        pharmacyId: p.id,
        pharmacy: { ...p },
      }));
    }
    return entity;
  }

  getAllFiles(entityParams, queryParams?) {
    return this.entityFileApiHelperService.getAllFiles(this, entityParams, queryParams);
  }

  getFileById(entityParams, fileId) {
    return this.entityFileApiHelperService.getFileById(this, entityParams, fileId);
  }

  uploadFile(entityParams, files) {
    return this.entityFileApiHelperService.uploadFile(this, entityParams, files);
  }

  deleteFile(entityParams, fileId) {
    return this.entityFileApiHelperService.deleteFile(this, entityParams, fileId);
  }

  downloadFile(file: EntityFile) {
    this.entityFileApiHelperService.downloadFile(this, file);
  }
}
