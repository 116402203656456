import { AdministrationRoute } from './administration-route';
import { DosageForm } from './dosage-form';
import { DrugCompany } from './drug-company';
import { Laboratory } from './laboratory';
import { MaterialType } from './material-type';
import { Monodrug } from './monodrug';
import { PharmacologicalAction } from './pharmacological-action';
import { PotencyUnit } from './potency-unit';
import { UnitType } from './unit-type';

export enum MedicationDrugCompanyBarcodeType {
  HC = 'HC',
  HK = 'HK',
  IC = 'IC',
  UC = 'UC',
  VC = 'VC',
  Z1 = 'Z1',
  Z2 = 'Z2',
  Z3 = 'Z3',
  Z4 = 'Z4',
}

export interface MedicationDrugCompany {
  id?: number;
  drugCompanyId?: number;
  barcodeType?: MedicationDrugCompanyBarcodeType;
  barcode?: string;
  discountPercentage?: number;
  drugCompany?: DrugCompany;
  SAPCode?: string;
}

export interface MedicationPriceHistory {
  id?: number;
  medicationId?: number;
  price?: number;
  date?: Date;
}

export interface Medication {
  id?: number;
  registerNumber?: string;
  dieCode?: string;
  name?: string;
  presentation?: string;
  potency?: string;
  price?: number;
  date?: Date;
  barcode?: string;
  unitsALF?: number;
  unitsINT?: number;
  codeATC?: string;
  r310financierAmount?: number;
  r310minimumBeneficiaryAmount?: number;
  r310?: boolean;
  refrigerator?: boolean;
  industryContribution?: boolean;
  inactive?: boolean;
  disabled?: boolean;
  traceable?: boolean;
  iva?: boolean;
  laboratoryId?: number;
  pharmacologicalActionId?: number;
  monodrugId?: number;
  dosageFormId?: number;
  potencyUnitId?: number;
  unitTypeId?: number;
  administrationRouteId?: number;
  materialTypeId?: number;
  drugCompanies?: MedicationDrugCompany[];
  medicationPriceHistories?: MedicationPriceHistory[];
  createdAt?: Date;
  updatedAt?: Date;
  laboratory?: Laboratory;
  pharmacologicalAction?: PharmacologicalAction;
  monodrug?: Monodrug;
  dosageForm?: DosageForm;
  potencyUnit?: PotencyUnit;
  unitType?: UnitType;
  administrationRoute?: AdministrationRoute;
  materialType?: MaterialType;
  vademecums?: Medication[];
}
