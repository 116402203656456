import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExecutedAccountableItem } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';
import { environment } from '@environment';
import { parseUtcDateProperties } from '../utils';

@Injectable()
export class ExecutedAccountableItemApiService extends ResourceEntityApiService<ExecutedAccountableItem> {
  resourceUrl = 'executed-accountable-items';

  constructor(protected http: HttpClient) {
    super(http);
  }

  parseEntity(entity) {
    parseUtcDateProperties(entity.executedBillingDocument, ['documentDate', 'documentPresentationDate']);
    parseUtcDateProperties(entity.executedRefundingDocument, ['documentDate', 'documentPresentationDate']);
    parseUtcDateProperties(entity.executedPharmacyDocument, ['documentDate', 'documentPresentationDate']);
    parseUtcDateProperties(entity.executedDocument, ['documentDate', 'documentPresentationDate']);
    return entity;
  }

  createExecutedBillingDocument(data, id) {
    return this.http.put<any>(
      `${environment.apiUrl}/${this.resourceUrl}/${id}/createExecutedBillingDocument`,
      data
    );
  }

  updateExecutedBillingDocument(data, accountableItemId, accountableDocumentId) {
    return this.http.put<any>(
      `${environment.apiUrl}/${this.resourceUrl}/${accountableItemId}/updateExecutedBillingDocument/${accountableDocumentId}`,
      data
    );
  }

}
