import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
})
export class LoadingDialogComponent {
  
  message: string;

  constructor(public config: DynamicDialogConfig) {
    this.message = this.config?.data?.message;
  }

}
