import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Dashboard , ApiList,} from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';
import { Observable } from 'rxjs';

@Injectable()
export class DashboardApiService extends ResourceEntityApiService<Dashboard> {
  resourceUrl = 'dashboard';
  getAllUrl= 'getSummary';

  constructor(protected http: HttpClient) {
    super(http);
  }

  getSummary(currentDateTime): Observable<ApiList<Dashboard>> {
    // parametro usado solo por temas de cache y poder tener los datos actualizados.
    // El endpoint ignora el parametro
      return this.http.get<ApiList<Dashboard>>(`${environment.apiUrl}/dashboard/getSummary/${currentDateTime}`);
  }


}
