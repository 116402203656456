import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountableDocumentApiService } from './accountable-document-api.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '@environment';

@Injectable()
export class ExecutedBillingDocumentApiService extends AccountableDocumentApiService {
  getAllUrl = 'executed-billing-documents';

  constructor(protected http: HttpClient) {
    super(http);
  }

  getComporbantePrefacturación(accountableDocumentId): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/${this.resourceUrl}/comprobante-prefacturacion/${accountableDocumentId}`).pipe(
      map(entity => this.parseEntity({ ...entity }))
    );
  }

}
