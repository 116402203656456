import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Dispatch } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';
import { parseUtcDateProperties } from '../utils';

@Injectable()
export class InverseLogisticsApiService extends ResourceEntityApiService<Dispatch> {
  resourceUrl = 'inverse-logistics';

  constructor(protected http: HttpClient) {
    super(http);
  }

  parseEntity(entity) {
    parseUtcDateProperties(entity, ['date', 'prescriptionDate', 'documentsReceptionDate']);
    return entity;
  }

}
