import { Address } from './address';
import { CoverageProgram } from './coverage-program';
import { Financier } from './financier';
import { Pathology } from './pathology';
import { Pharmacy } from './pharmacy';
import { Diagnosis} from './diagnosis';

export enum BeneficiaryDocumentType {
  DNI = 'DNI',
  CUIL = 'CUIL',
  CI = 'CI',
  LC = 'LC',
  LE = 'LE',
}

export enum BeneficiaryGender {
  Male = 'Male',
  Female = 'Female',
}

export interface BeneficiaryContact {
  id?: number;
  beneficiaryId?: number;
  name?: string;
  label?: string;
  phone?: string;
  email?: string;
}

export interface BeneficiaryCoverageProgram {
  id?: number;
  beneficiaryId?: number;
  coverageProgramId?: number;
  startDate?: Date;
  endDate?: Date;
  coverage?: number;
  shippingPharmacyId?: number;
  coverageProgram?: CoverageProgram;
  shippingPharmacy?: Pharmacy;
  pathologyId?: number;
  pathology?: Pathology;
}

export interface BeneficiaryPathology {
  id?: number;
  beneficiaryId?: number;
  pathologyId?: number;
  pathology?: Pathology;
  diagnosisId?: number;
  diagnosis?: Diagnosis;
}

export interface BeneficiaryDisabilityCertificate {
  id?: number;
  beneficiaryId?: number;
  title?: string;
  startDate?: Date;
  endDate?: Date;
}

export interface BeneficiaryDiagnosisSummary {
  id?: number;
  beneficiaryId?: number;
  summary?: string;
  date?: Date;
}

export interface Beneficiary {
  id?: number;
  financierId?: number;
  documentType?: BeneficiaryDocumentType;
  documentNumber?: string;
  cuil?: string;
  credentialNumber?: string;
  lastName?: string;
  firstName?: string;
  fullName?: string;
  birthdate?: Date;
  gender?: BeneficiaryGender;
  plan?: string;
  address?: Address;
  shippingAddress?: Address;
  active?: boolean;
  diabetes?: boolean;
  disability?: boolean;
  notUpgradeable?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  contacts?: BeneficiaryContact[];
  coveragePrograms?: BeneficiaryCoverageProgram[];
  pathologies?: BeneficiaryPathology[];
  disabilityCertificates?: BeneficiaryDisabilityCertificate[];
  diagnosisSummaries?: BeneficiaryDiagnosisSummary[];
  financier?: Financier;
  inactivationDate?: Date;
}
