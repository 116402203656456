import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRolesService } from '../services/user-roles.service';

@Injectable({ providedIn: 'root' })
export class RoleGuard implements CanActivate {
  constructor(
    private router: Router,
    private userRolesService: UserRolesService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userRolesService.getHasRoles(route.data.roles, route.data.anyRole).pipe(
      map(result => {
        if (result) {
          return true;
        }

        this.router.navigate(['/home/403']);
        return false;
      })
    );
  }
}
