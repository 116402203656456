import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiList, CoverageProgram, QueryParams } from '../models';
import { getHttpParams, processApiList } from '../utils';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class CoverageProgramApiService extends ResourceEntityApiService<CoverageProgram> {
  resourceUrl = 'coverage-programs';

  constructor(protected http: HttpClient) {
    super(http);
  }

  getByFinancier(financierId, queryParams?: QueryParams): Observable<ApiList<CoverageProgram>> {
    return this.http.get<ApiList<CoverageProgram>>(
      `${environment.apiUrl}/financiers/${financierId}/coverage-programs`,
      { params: getHttpParams(queryParams) }
    ).pipe(
      tap(processApiList),
      map(list => this.parseList(list))
    );
  }

  getByBeneficiaryAndMedication(beneficiaryId, medicationId?, queryParams?: QueryParams): Observable<ApiList<CoverageProgram>> {
    let params = getHttpParams(queryParams);
    if (medicationId) {
      params = params.set('medicationId', medicationId.toString());
    }
    return this.http.get<ApiList<CoverageProgram>>(
      `${environment.apiUrl}/beneficiaries/${beneficiaryId}/coverage-programs`,
      { params }
    ).pipe(
      tap(processApiList),
      map(list => this.parseList(list))
    );
  }

  getCoverageProgramPotencies(coverageProgramId, monodrugId) {
    let params = new HttpParams();
    if (monodrugId) {
      params = params.set('monodrugId', monodrugId.toString());
    }
    return this.http.get<string[]>(`${environment.apiUrl}/coverage-programs/${coverageProgramId}/potencies`, { params });
  }
}
