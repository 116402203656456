import { HttpClient } from '@angular/common/http';
import { AccountableDocument } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

export abstract class AccountableDocumentApiService extends ResourceEntityApiService<AccountableDocument> {
  resourceUrl = 'accountable-documents';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
