import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { tap } from 'rxjs/operators';
import { ApiList, Medication, QueryParams } from '../models';
import {
  getHttpParams,
  parseUtcDateProperties,
  processApiList,
} from '../utils';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class MedicationApiService extends ResourceEntityApiService<Medication> {
  resourceUrl = 'medications';

  constructor(protected http: HttpClient) {
    super(http);
  }

  parseEntity(entity) {
    parseUtcDateProperties(entity, ['date']);
    parseUtcDateProperties(entity.medicationPriceHistories, ['date']);
    return entity;
  }

  getBeneficiaryMedications(beneficiaryId, queryParams) {
    return this.http
      .get<ApiList<Medication>>(
        `${environment.apiUrl}/beneficiaries/${beneficiaryId}/medications`,
        { params: getHttpParams(queryParams) }
      )
      .pipe(tap(processApiList));
  }

  getFinancierMedications(financierId, queryParams) {
    return this.http
      .get<ApiList<Medication>>(
        `${environment.apiUrl}/financiers/${financierId}/medications`,
        { params: getHttpParams(queryParams) }
      )
      .pipe(tap(processApiList));
  }

  getCoverageProgramMedications(coverageProgramId, queryParams) {
    return this.http
      .get<ApiList<Medication>>(
        `${environment.apiUrl}/coverage-programs/${coverageProgramId}/medications`,
        { params: getHttpParams(queryParams) }
      )
      .pipe(tap(processApiList));
  }

  getMedicationsByTreatment(beneficiaryId, financierId, queryParams) {
    return this.http
      .get<ApiList<Medication>>(
        `${environment.apiUrl}/medications/treatment/${beneficiaryId}/${financierId}/medications`,
        { params: getHttpParams(queryParams) }
      )
      .pipe(tap(processApiList));
  }

  getMedicationByDistributionType(
    coverageProgramId,
    distributionType,
    specialCoverage,
    financierId,
    queryParams
  ) {
    return this.http
      .get<ApiList<Medication>>(
        `${environment.apiUrl}/coverage-programs/${coverageProgramId}/${distributionType}/${financierId}/${specialCoverage}/medications`,
        { params: getHttpParams(queryParams) }
      )
      .pipe(tap(processApiList));
  }

  getByIdAndVademecum(id, vademecumId) {
    return this.http
      .get<ApiList<Medication>>(
        `${environment.apiUrl}/medications/${id}/vademecumId/${vademecumId}`
      )
      .pipe(tap(processApiList));
  }
}
