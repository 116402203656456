export interface DrugCompanyBusinessType {
  id?: number;
  drugCompanyId?: number;
  name?: string;
  versionCode?: string;
  logisticOperatorId?: number;
  logisticOperator?: string;
  interfaceModel?: InterfaceModel;
}

export enum DrugCompanyProcessType {
  'Drg FNX' = 'Drg FNX',
  'Drg DDS' = 'Drg DDS',
}

export enum InterfaceModel {
  'jsonWithText' = 'jsonWithText',
  'jsonWithDeliveryNote' = 'jsonWithDeliveryNote',
}

export interface DrugCompany {
  id?: number;
  name?: string;
  processType?: DrugCompanyProcessType;
  businessTypes?: DrugCompanyBusinessType[];
  createdAt?: Date;
  updatedAt?: Date;
}
