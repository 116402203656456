import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AdministrationRoute, CancelEntity, CoverageProgram, DosageForm, EntityFile, Prescription } from '../models';
import { parseUtcDateProperties } from '../utils';
import { EntityFileApiHelperService } from './entity-file-api-helper.service';
import { EntityFileApiService } from './entity-file-api-service.type';
import { ResourceEntityApiService } from './resource-entity-api-service';
import { tap } from 'rxjs/operators';
import { CommonMessageService } from '@app/shared/common';
import { TranslateService } from '@ngx-translate/core';
import { Translations } from '@app/core';


export interface PrescriptionItemFilters {
  administrationRoutes: AdministrationRoute[];
  dosageForms: DosageForm[];
  coveragePrograms: CoverageProgram[];
  potencies: any[];
}

@Injectable()
export class PrescriptionApiService extends ResourceEntityApiService<Prescription> implements EntityFileApiService {
  resourceUrl = 'prescriptions';

  constructor(protected http: HttpClient,
              protected entityFileApiHelperService: EntityFileApiHelperService,
              protected commonMessageService: CommonMessageService,
              private translate: TranslateService,
              private readonly lang: Translations
              ) {
    super(http);
  }

  parseEntity(entity) {
    parseUtcDateProperties(entity, ['date', 'saleDate', 'provisionLimitDate', 'treatmentExpirationDate']);
    parseUtcDateProperties(entity.beneficiary, ['birthdate']);
    parseUtcDateProperties(entity.beneficiary?.coveragePrograms, ['startDate', 'endDate']);
    parseUtcDateProperties(entity.beneficiary?.diagnosisSummaries, ['date']);
    parseUtcDateProperties(entity.beneficiary?.disabilityCertificates, ['startDate', 'endDate']);
    parseUtcDateProperties(entity.beneficiary?.diabetesCertificates, ['certificateDate']);
    return entity;
  }

  getItemFilters(options: {
    beneficiaryId: number,
    monodrugId: number,
    administrationRouteId?: number,
    dosageFormId?: number,
    potency?: string,
  }): Observable<PrescriptionItemFilters> {
    let httpParams = new HttpParams();
    httpParams = httpParams.set('beneficiaryId', options.beneficiaryId.toString());
    httpParams = httpParams.set('monodrugId', options.monodrugId.toString());

    if (options.administrationRouteId) {
      httpParams = httpParams.set('administrationRouteId', options.administrationRouteId.toString());
    }

    if (options.dosageFormId) {
      httpParams = httpParams.set('dosageFormId', options.dosageFormId.toString());
    }

    if (options.potency) {
      httpParams = httpParams.set('potency', options.potency);
    }

    return this.http.get<PrescriptionItemFilters>(`${this.getCollectionUrl()}/item-filters`, { params: httpParams });
  }

  prescribe(data) {
    return this.http.post<Prescription>(`${this.getCollectionUrl()}`, data).pipe(
      map(entity => this.parseEntity({ ...entity }))
    );
  }

  authorize(data) {
    return this.http.post<Prescription>(`${this.getCollectionUrl()}/authorize`, data).pipe(
      map(entity => this.parseEntity({ ...entity }))
    );
  }

  audit(data) {
    return this.http.post<Prescription>(`${this.getCollectionUrl()}/audit`, data).pipe(
      map(entity => this.parseEntity({ ...entity }))
    );
  }

  validate(data) {
    return this.http.post<Prescription>(`${this.getCollectionUrl()}/validate`, data).pipe(
      map((result: any) => {
        if(!result?.prescription || result?.result){
          return result
        }
        return({
          prescription: this.parseEntity({ ...result.prescription }),
          dispatch: result.dispatch
        })
      })
    );
  }

  cancel(data) {
    return this.http.post<CancelEntity>(`${this.getItemUrl(data)}/cancel`, data).pipe(tap(response => {
      if (response['value'] === false){
        this.commonMessageService.error(this.translate.instant(this.lang.HOME.CANCEL.entityNotCanceled) + this.translate.instant(this.lang.ENTITIES.PRESCRIPTION.FIELDS.STATUS[response['status']]));
      }
      else{
        this.commonMessageService.success(this.translate.instant(this.lang.HOME.CANCEL.entityCanceled));
      }
    }))
  }

  notify(prescriptionId, data) {
    return this.http.post(`${this.getCollectionUrl()}/${prescriptionId}/notify`, data);
  }

  misValidacionesProcess(data) {
    return this.http.post(`${this.getCollectionUrl()}/mis-validaciones/process`, data);
  }

  getAllFiles(entityParams, queryParams?) {
    return this.entityFileApiHelperService.getAllFiles(this, entityParams, queryParams);
  }

  getFileById(entityParams, fileId) {
    return this.entityFileApiHelperService.getFileById(this, entityParams, fileId);
  }

  uploadFile(entityParams, files) {
    return this.entityFileApiHelperService.uploadFile(this, entityParams, files);
  }

  deleteFile(entityParams, fileId) {
    return this.entityFileApiHelperService.deleteFile(this, entityParams, fileId);
  }

  downloadFile(file: EntityFile) {
    this.entityFileApiHelperService.downloadFile(this, file);
  }
}
