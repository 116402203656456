<p-fileUpload #fileUpload
              mode="basic"
              name="file"
              maxFileSize="12582912"
              [multiple]="false"
              customUpload="true"
              [auto]="auto"
              (uploadHandler)="upload($event)"
              chooseLabel="{{ label || (lang.SHARED.FILES.addFile | translate) }}"
              [disabled]="uploading"
              chooseIcon="ui-icon-cloud-upload"
></p-fileUpload>
<p-progressBar mode="indeterminate" [hidden]="!uploading"></p-progressBar>
