import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { environment } from '@environment';
import firebase from 'firebase/app';
import { AuthenticationService } from '../authentication/authentication.service';
import { User } from '@app/shared/api';
import { catchError, tap } from 'rxjs/operators';

@Injectable()
export class ApiRequestLoggerInterceptor implements HttpInterceptor {
  
  currentUser: User  = this.authenticationService.currentUserValue;

  constructor(private authenticationService: AuthenticationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if(firebase.apps.length === 0) {
      return next.handle(req);
    }
    
    if (!req.url.includes(environment.apiUrl)) {
      return next.handle(req);
    }

    const parsedUrl: any = this.parseUrl(req.url);
    const eventName: string = parsedUrl.request;
    const eventParams: any = {
      type: 'API_REQUEST',
      user_type: this.currentUser?.entityType,
      user_id: this.currentUser?.id,
      user_name: this.currentUser?.name,
      entity: parsedUrl.entity,
      request: req.urlWithParams,
      start_date: new Date(),
    }

    return next.handle(req).pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          eventParams.end_date = new Date();
          eventParams.success = event.ok;
          eventParams.value = (eventParams.end_date - eventParams.start_date) / 1000;
          firebase.analytics().logEvent(eventName, eventParams);
        }
      }),
    );
  }

  parseUrl(url: string) {
    const parsedUrl: any = {};

    parsedUrl.request = url.replace(`${environment.apiUrl}/`, '').replace(/\/\d+/g, '/entity_id');
    parsedUrl.entity = parsedUrl.request.substring(0, parsedUrl.request.indexOf('/') > 0 ? parsedUrl.request.indexOf('/') : parsedUrl.request.length);

    return parsedUrl;
  }
}
