import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { EntityFile, Financier } from '../models';
import { EntityFileApiHelperService } from './entity-file-api-helper.service';
import { EntityFileApiService } from './entity-file-api-service.type';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class FinancierApiService
  extends ResourceEntityApiService<Financier>
  implements EntityFileApiService
{
  resourceUrl = 'financiers';

  constructor(
    protected http: HttpClient,
    protected entityFileApiHelperService: EntityFileApiHelperService
  ) {
    super(http);
  }

  create(data, params) {
    if (data.logo) {
      const formData = new FormData();
      formData.append('file', data.logo);
      const document = Object.assign({}, data);
      delete data.logo;
      formData.append('document', document);
      return super.create(formData);
    }

    return super.create(data, params);
  }

  importBeneficiaries(file, action, financierID)  {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('action', action);
    formData.append('financierID', financierID);
    return this.http.post<any>(
      `${environment.apiUrl}/financiers/import/beneficiaries`,
      formData
    );
  }

  getAllFiles(entityParams, queryParams?) {
    return this.entityFileApiHelperService.getAllFiles(
      this,
      entityParams,
      queryParams
    );
  }

  getFileById(entityParams, fileId) {
    return this.entityFileApiHelperService.getFileById(
      this,
      entityParams,
      fileId
    );
  }

  uploadFile(entityParams, files) {
    return this.entityFileApiHelperService.uploadFile(
      this,
      entityParams,
      files
    );
  }

  deleteFile(entityParams, fileId) {
    return this.entityFileApiHelperService.deleteFile(
      this,
      entityParams,
      fileId
    );
  }

  downloadFile(file: EntityFile) {
    this.entityFileApiHelperService.downloadFile(this, file);
  }

  getFinancierByBeneficiaryId(beneficiaryId: number) {
    return this.http.get<Financier>(
      `${environment.apiUrl}/financiers/beneficiaryId/${beneficiaryId}`
    );
  }
}
