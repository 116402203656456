import { HttpParams } from '@angular/common/http';
import { QueryParams } from '../models';

export function getHttpParams(params: QueryParams = {}) {
  let httpParams = new HttpParams();

  if (params.skip) {
    httpParams = httpParams.set('$skip', params.skip.toString());
  }
  if (params.top) {
    httpParams = httpParams.set('$top', params.top.toString());
  }
  if (params.count) {
    httpParams = httpParams.set('$inlinecount', 'allpages');
  }
  if (params.select) {
    httpParams = httpParams.set('$select', params.select);
  }
  if (params.filter) {
    httpParams = httpParams.set('$filter', params.filter);
  }
  if (params.orderby) {
    httpParams = httpParams.set('$orderby', params.orderby);
  }
  if (params.search) {
    httpParams = httpParams.set('search', params.search);
  }

  return httpParams;
}
