import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { PageItem } from '../../../../home/services';
import { BreadcrumbService } from '../../services/breadcrumb.service';

@Component({
  selector: 'app-page-menu',
  templateUrl: './page-menu.component.html',
})
export class PageMenuComponent implements OnInit {
  data: PageItem;
  items: MenuItem[] = [];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit() {
    this.route.data.subscribe(routerData => {
      this.breadcrumbService.setItems(routerData.page.breadcrumbs);
      this.data = routerData.page.pageItem;
      this.items = routerData.page.pageItem.items
        .filter(item => item.visible)
        .map(item => ({
          label: item.label,
          icon: item.icon,
          routerLink: `/home/${item.routerLink}`,
        }));
    });
  }
}
