import { Injectable } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Injectable()
export class PrimeNGCorrectionService {
  init() {
    this.installOverlayPanelFix();
  }

  private installOverlayPanelFix() {
    OverlayPanel.prototype.onAnimationStart = function(this: OverlayPanel, event: any) {
      if (event.toState === 'open') {
        this.container = event.element;
        this.onShow.emit(null);
        this.appendContainer();
        this.align();
        setTimeout(() => {
          this.bindDocumentClickListener();
          this.bindDocumentResizeListener();
          this.isContainerClicked = false;
        });

        if (this.focusOnShow) {
          this.focus();
        }
      }
    };
  }
}
