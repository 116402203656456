import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, first, shareReplay, tap } from 'rxjs/operators';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  private cache: Record<string, Observable<HttpEvent<any>>> = {};

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.method !== 'GET') {
      this.cache = {};
      return next.handle(req);
    }

    if (req.params.has('$inlinecount')) {
      return next.handle(req);
    }

    let cachedObservable = this.cache[req.urlWithParams];

    if (!cachedObservable) {
      cachedObservable = next.handle(req).pipe(
        filter((res) => res instanceof HttpResponse),
        shareReplay(1),
      );
      this.cache[req.urlWithParams] = cachedObservable;
    }

    return cachedObservable.pipe(first());
  }
}
