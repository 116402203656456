import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MaterialType } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class MaterialTypeApiService extends ResourceEntityApiService<MaterialType> {
  resourceUrl = 'material-types';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
