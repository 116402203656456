import { ModuleWithProviders, NgModule } from '@angular/core';
import { FileSaverModule } from '@app/shared/file-saver';
import {
  AdministrationRouteApiService,
  AlfabetaApiService,
  AuditLogApiService,
  BeneficiaryApiService,
  BeneficiaryDiabetesCertificateApiService,
  BeneficiaryTreatmentApiService,
  CommercialAgreementApiService,
  CountryApiService,
  CoverageProgramApiService,
  DebitCreditReasonsApiService,
  DiagnosisApiService,
  DispatchApiService,
  DispatchItemApiService,
  DistributeApiService,
  DistributionTypeApiService,
  DoctorApiService,
  DosageFormApiService,
  DrugCompanyApiService,
  CompanyApiService,
  EntityFileApiHelperService,
  FinancierApiService,
  GroupApiService,
  IncidentApiService,
  IncidentAreaApiService,
  IncidentReasonApiService,
  InsulineTypeApiService,
  InvoiceTypeApiService,
  LaboratoryApiService,
  LiquidationApiService,
  LocalityApiService,
  LogisticOperatorApiService,
  MaterialTypeApiService,
  MedicalSpecialityApiService,
  MedicationApiService,
  MedicationPriceApiService,
  MonodrugApiService,
  PathologyApiService,
  PaybackEntityApiService,
  PharmacologicalActionApiService,
  PharmacyApiService,
  PharmacyBatchApiService,
  PharmacyGroupApiService,
  PotencyUnitApiService,
  PrescriptionApiService,
  PrescriptionItemApiService,
  RegionApiService,
  ReportsApiService,
  ReportsTemplateApiService,
  ReportsViewApiService,
  RoleApiService,
  SAPDistributionRuleApiService,
  UnitTypeApiService,
  UserApiService,
  VademecumApiService,
  VademecumItemApiService,
  ZoneApiService,
  ZoneLocalityApiService,
  InverseLogisticsApiService,
  AuditedAccountableItemApiService,
  ExecutedAccountableItemApiService,
  AccountableDocumentApiService,
  ExecutedBillingDocumentApiService,
  ExecutedRefundingDocumentApiService,
  LogisticDataApiService,
  PharmacyAgreementRuleApiService,
  DashboardApiService
} from './services';

@NgModule({
  imports: [
    FileSaverModule,
  ],
})
export class ApiModule {
  static forRoot(): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        AdministrationRouteApiService,
        AlfabetaApiService,
        AuditLogApiService,
        CommercialAgreementApiService,
        BeneficiaryApiService,
        BeneficiaryDiabetesCertificateApiService,
        BeneficiaryTreatmentApiService,
        CountryApiService,
        CoverageProgramApiService,
        DiagnosisApiService,
        DebitCreditReasonsApiService,
        DispatchApiService,
        DispatchItemApiService,
        DistributeApiService,
        DistributionTypeApiService,
        DoctorApiService,
        DosageFormApiService,
        DrugCompanyApiService,
        CompanyApiService,
        EntityFileApiHelperService,
        FinancierApiService,
        GroupApiService,
        IncidentApiService,
        IncidentAreaApiService,
        IncidentReasonApiService,
        InsulineTypeApiService,
        InvoiceTypeApiService,
        LaboratoryApiService,
        LiquidationApiService,
        LocalityApiService,
        LogisticOperatorApiService,
        MaterialTypeApiService,
        MedicalSpecialityApiService,
        MedicationApiService,
        MedicationPriceApiService,
        MonodrugApiService,
        PathologyApiService,
        PaybackEntityApiService,
        PharmacologicalActionApiService,
        PharmacyApiService,
        PharmacyBatchApiService,
        PharmacyGroupApiService,
        PotencyUnitApiService,
        PrescriptionApiService,
        PrescriptionItemApiService,
        RegionApiService,
        ReportsApiService,
        ReportsTemplateApiService,
        ReportsViewApiService,
        RoleApiService,
        SAPDistributionRuleApiService,
        UnitTypeApiService,
        UserApiService,
        VademecumApiService,
        VademecumItemApiService,
        ZoneApiService,
        ZoneLocalityApiService,
        InverseLogisticsApiService,
        AuditedAccountableItemApiService,
        ExecutedAccountableItemApiService,
        ExecutedBillingDocumentApiService,
        ExecutedRefundingDocumentApiService,
        LogisticDataApiService,
        PharmacyAgreementRuleApiService,
        DashboardApiService
      ]
    };
  }
}
