import { registerLocaleData } from '@angular/common';
import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Translations } from '@app/core';
import { ErrorDetailsComponent } from '@app/shared/common';
import { PrimeNGCorrectionService } from '@app/shared/common/services/primeng-corrections.service';
import { TranslateService } from '@ngx-translate/core';
import { Message, PrimeNGConfig } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Toast } from 'primeng/toast';
import firebase from 'firebase/app';
import 'firebase/analytics';
import { environment } from '@environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  @ViewChild('appToast') appToast: Toast;


  langId: string;
  localeId: string;

  constructor(
    private primengConfig: PrimeNGConfig,
    public zone: NgZone,
    private dialogService: DialogService,
    private router: Router,
    public translate: TranslateService,
    public readonly lang: Translations,
    private primeNGCorrectionService: PrimeNGCorrectionService,
  ) {
    this.primeNGCorrectionService.init();

    this.translate.setDefaultLang('es');

    this.langId = 'es';
    this.localeId = 'es-AR';

    this.localeInitializer();
  }

  ngOnInit() {
    this.primengConfig.ripple = true;

    // Initialize Firebase
    if(environment.firebaseConfig) {
      const app = firebase.initializeApp(environment.firebaseConfig);
      app.analytics();
    }
  }

  localeInitializer() {
    this.translate.use(this.langId);

    return import(
      /* webpackInclude: /(es-AR)\.js$/ */
      `@angular/common/locales/${this.localeId}.js`
      ).then(module => registerLocaleData(module.default));
  }

  showErrorDetail(message: Message) {
    const messageIndex = this.appToast.messages.findIndex(item => item.id === message.id);
    this.appToast.onMessageClose({
      index: messageIndex,
      message
    });

    this.dialogService.open(ErrorDetailsComponent, {
      header: this.translate.instant(this.lang.COMMON.errorDetails),
      data: {
        message,
        path: this.router.url,
      },
      width: '70vw',
      baseZIndex: 1000010000,
    });
  }
}
