import { DebitCreditReasons } from './debit-credit-reason';
import { Dispatch } from './dispatch';
import { Medication } from './medication';
import { Monodrug } from './monodrug';
import { PharmacyBatch } from './pharmacy-batch';
import { PrescriptionItem } from './prescription-item';

export enum DispatchItemStatus {
  'Dispatched' = 'Dispatched',
  'Canceled' = 'Canceled',
  'Audited' = 'Audited'
}

export enum DispatchItemPaymentMethod {
  'Transfer' = 'Transfer',
  'DrugCompany' = 'DrugCompany',
}

export enum DispatchItemPaymentDeadline {
  'Deadline0' = '0',
  'Deadline21' = '21',
  'Deadline30' = '30',
  'Deadline45' = '45',
  'Deadline60' = '60',
  'Deadline90' = '90',
}

export enum DispatchItemPaymentPriority {
  'NonSpecified' = 'NonSpecified',
  'Week1' = 'Week1',
  'Week2' = 'Week2',
  'Week3' = 'Week3',
  'Week4' = 'Week4',
}

export enum DispatchItemLiquidateMethod {
  'MerchandisePercent' = 'MerchandisePercent',
  'ServiceFixedAmount' = 'ServiceFixedAmount',
  'ServicePercent' = 'ServicePercent',
}

export interface DispatchItem {
  id: number;
  dispatchId: number;
  prescriptionItemId: number;
  medicationId: number;
  monodrugId: number;
  debitCreditReasonId: number | any;
  status: DispatchItemStatus;
  financierCoverage: number;
  beneficiaryCoverage: number;
  billedPercent: number;
  amount: number;
  unitPrice: number;
  totalPrice: number;
  paymentMethod?: DispatchItemPaymentMethod;
  paymentDeadline?: DispatchItemPaymentDeadline;
  paymentPriority?: DispatchItemPaymentPriority;
  liquidateMethod?: DispatchItemLiquidateMethod;
  liquidateValue?: number;
  liquidateAmount?: number;
  prescribesBrand: boolean;
  prescribedBrandOnly: boolean;
  institutionalDie: boolean;
  prolongedTreatment: boolean;
  authorizedDispatch: boolean;
  pharmacyBatchId?: number;
  debit?: number;
  debitReason?: string;
  credit?: number;
  creditReason?: string;
  paymentTotal?: number;
  financierTotal?: number;
  beneficiaryTotal?: number;
  presentedFinancierCoverage?: number;
  presentedBeneficiaryCoverage?: number;
  presentedBilledPercent?: number;
  presentedUnitPrice?: number;
  presentedTotalPrice?: number;
  presentedAmount?: number;
  presentedFinancierTotal?: number;
  presentedBeneficiaryTotal?: number;
  presentedPaymentTotal?: number;
  auditedFinancierCoverage?: number;
  auditedBeneficiaryCoverage?: number;
  auditedBilledPercent?: number;
  auditedUnitPrice?: number;
  auditedTotalPrice?: number;
  auditedAmount?: number;
  auditedFinancierTotal?: number;
  auditedBeneficiaryTotal?: number;
  auditedPaymentTotal?: number;
  createdAt?: Date;
  updatedAt?: Date;
  debitCreditReason?: DebitCreditReasons;
  dispatch?: Dispatch;
  prescriptionItem?: PrescriptionItem;
  medication?: Medication;
  monodrug?: Monodrug;
  pharmacyBatch?: PharmacyBatch;
  date?: Date;
  PaybackEntityPriceListPercent?: number;
}
