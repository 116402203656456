import { Injectable } from '@angular/core';
import { AuthenticationService } from '../authentication/authentication.service';
import { LocalStorageService } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class UserSettingsService {
  constructor(private authenticationService: AuthenticationService,
              private localStorageService: LocalStorageService) { }

  get(setting: string): any {
    const key = this.getKeyName(setting);
    return this.localStorageService.get(key);
  }

  set(setting: string, value: any): void {
    const key = this.getKeyName(setting);
    this.localStorageService.set(key, value);
  }

  remove(setting: string): void {
    const key = this.getKeyName(setting);
    this.localStorageService.remove(key);
  }

  getUserId(): number {
    if (!this.authenticationService.isLoggedIn()) {
      throw new Error('Not logged in');
    }
    return this.authenticationService.currentUserValue.id;
  }

  getKeyName(setting: string): string {
    const userId = this.getUserId();
    return `${userId}-${setting}`;
  }
}
