import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApiList, QueryParams, Region } from '../models';
import { getHttpParams, processApiList } from '../utils';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class RegionApiService extends ResourceEntityApiService<Region> {
  resourceUrl = 'regions';

  constructor(protected http: HttpClient) {
    super(http);
  }

  /**
   * @description This method brings a list of regions asociated with a country searching this country by name
   * this method was created to be used by address component but can be used for anyone need it 
   * @param {string} countryName name of country to be searched for
   * @param {QueryParams} queryParams params to filter the regions
   * @returns {Observable<ApiList<Region>>} dispatch items and prescription items list
   */
   getRegionsByCountryName(
    countryName: string,
    queryParams?: QueryParams
  ): Observable<ApiList<Region>> {
    return this.http
      .get<ApiList<Region>>(
        `${environment.apiUrl}/regions/countryName/${countryName}`,
        {params: getHttpParams(queryParams),}
      )
      .pipe(
        tap(processApiList),
        map((list) => this.parseList(list))
      );
  }
}
