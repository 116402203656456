import { Component, ElementRef, ViewChild } from '@angular/core';
import { Translations } from '@app/core';
import { Message } from 'primeng/api';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-error-details',
  templateUrl: './error-details.component.html'
})
export class ErrorDetailsComponent {
  @ViewChild('textArea') textArea: ElementRef;

  message: Message;
  path: string;

  constructor(public config: DynamicDialogConfig,
              public readonly lang: Translations) {
    this.message = this.config?.data?.message;
    this.path = this.config?.data?.path;
  }

  copyError() {
    this.textArea.nativeElement.select();
    document.execCommand('copy');
    this.textArea.nativeElement.setSelectionRange(0, 0);
  }
}
