import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { map, tap } from 'rxjs/operators';
import { ApiList, BeneficiaryTreatment, QueryParams } from '../models';
import {
  getHttpParams,
  parseUtcDateProperties,
  processApiList,
} from '../utils';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class BeneficiaryTreatmentApiService extends ResourceEntityApiService<BeneficiaryTreatment> {
  constructor(protected http: HttpClient) {
    super(http);
  }

  protected getCollectionUrl(data?: any, params?: any): string {
    return `${environment.apiUrl}/beneficiaries/${
      data?.beneficiaryId || params.beneficiaryId
    }/treatments`;
  }

  protected getItemUrl(data: any, params?: any): string {
    return `${environment.apiUrl}/beneficiaries/${
      data?.beneficiaryId || params.beneficiaryId
    }/treatments/${data.id}`;
  }

  parseEntity(entity) {
    parseUtcDateProperties(entity, ['startDate', 'endDate']);
    return entity;
  }

  getPendingItems(queryParams, currentUserId) {
    return this.http
      .get<ApiList<BeneficiaryTreatment>>(
        `${environment.apiUrl}/beneficiaries/treatments/audit/${currentUserId}`,
        { params: getHttpParams(queryParams) }
      )
      .pipe(
        tap(processApiList),
        map((list) => this.parseList(list))
      );
  }

  deleteTreatment(treatment){
    return this.http.delete(`${environment.apiUrl}/beneficiaries/${treatment.beneficiaryId}/treatments/${treatment.beneficiaryTreatmentId}`, treatment)
  }

  audit(data) {
    return this.http.put<any>(
      `${environment.apiUrl}/beneficiaries/treatments/audit`,
      data
    );
  }

  getLogs(beneficiaryTreatmentId: number, queryParams?: QueryParams) {
    return this.http
      .get<ApiList<BeneficiaryTreatment>>(
        `${environment.apiUrl}/beneficiaries/treatments/${beneficiaryTreatmentId}/logs`,
        { params: getHttpParams(queryParams) }
      )
      .pipe(tap(processApiList));
  }
}
