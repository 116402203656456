export enum SAPDistributionRuleContractType {
  'Capita' = 'Capita',
  'Provision' = 'Provision',
}

export interface SAPDistributionRule {
  id?: number;
  contractType: SAPDistributionRuleContractType;
  financierPercent: number;
  billedPercent: number;
  code: string;
  createdAt?: Date;
  updatedAt?: Date;
}
