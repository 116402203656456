import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdministrationRoute } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class AdministrationRouteApiService extends ResourceEntityApiService<AdministrationRoute> {
  resourceUrl = 'administration-routes';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
