<div class="p-mb-3">
  <button pButton pRipple type="button" class="p-button-primary" icon="ui-icon-content-copy"
          label="{{ lang.COMMON.copyInClipboard | translate }}"
          (click)="copyError()">
  </button>
</div>
<div class="p-mb-3">
    <textarea #textArea
              pInputTextarea
              readonly
              style="width: 100%; max-height: 500px; background: rgba(0, 0, 0, 0.1); font-family: monospace;"
              autoResize="autoResize"
    >{{ lang.COMMON.error | translate }}: {{ message?.summary }}
      {{ lang.COMMON.detail | translate }}: {{ message?.detail }}
      {{ lang.COMMON.path | translate }}: {{ path }}

      {{ message?.data?.errorPretty }}</textarea>
</div>
