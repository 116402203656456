import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company, EntityFile} from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';
import { EntityFileApiHelperService } from './entity-file-api-helper.service';

@Injectable()
export class CompanyApiService extends ResourceEntityApiService<Company> {
  resourceUrl = 'companies';

  constructor(protected http: HttpClient,
    protected entityFileApiHelperService: EntityFileApiHelperService
  ) {
    super(http);
  }

  getAllFiles(entityParams, queryParams?) {
    return this.entityFileApiHelperService.getAllFiles(
      this,
      entityParams,
      queryParams
    );
  }

  getFileById(entityParams, fileId) {
    return this.entityFileApiHelperService.getFileById(
      this,
      entityParams,
      fileId
    );
  }

  uploadFile(entityParams, files) {
    return this.entityFileApiHelperService.uploadFile(
      this,
      entityParams,
      files
    );
  }

  deleteFile(entityParams, fileId) {
    return this.entityFileApiHelperService.deleteFile(
      this,
      entityParams,
      fileId
    );
  }

  downloadFile(file: EntityFile) {
    this.entityFileApiHelperService.downloadFile(this, file);
  }
}
