import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import {
  AppTitleService,
  AuthInterceptor,
  DefaultRouteReuseStrategy,
  ErrorService,
  GlobalErrorHandler,
  LocalStorageService,
  ResponseHttpInterceptor,
  Translations,
  UserSettingsService,
  ApiRequestLoggerInterceptor,
} from '@app/core';
import { SharedCommonModule } from '@app/shared/common';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { TranslateCompiler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MESSAGE_FORMAT_CONFIG, TranslateMessageFormatCompiler } from 'ngx-translate-messageformat-compiler';
import { MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { from } from 'rxjs';
import { pluck } from 'rxjs/operators';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CacheInterceptor } from './core/interceptors/cache.interceptor';
import { AccessDeniedComponent } from './pages/accessdenied.component';
import { ErrorComponent } from './pages/error.component';
import { NotFoundComponent } from './pages/notfound.component';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string) {
    return from(import(`../assets/i18n/${lang}.ts`)).pipe(pluck('default'));
  }
}

@NgModule({
  declarations: [
    AppComponent,
    AccessDeniedComponent,
    ErrorComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    SharedCommonModule,
    LoadingBarRouterModule,
    TranslateModule.forRoot({
      compiler: {
        provide: TranslateCompiler,
        useClass: TranslateMessageFormatCompiler
      },
      loader: {
        provide: TranslateLoader,
        useClass: WebpackTranslateLoader
      }
    }),
  ],
  providers: [
    Translations,
    AppTitleService,
    ErrorService,
    LocalStorageService,
    DialogService,
    MessageService,
    UserSettingsService,
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseHttpInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiRequestLoggerInterceptor,
      multi: true
    },
    {
      provide: RouteReuseStrategy,
      useClass: DefaultRouteReuseStrategy
    },
    {
      provide: MESSAGE_FORMAT_CONFIG,
      useValue: {
        locales: ['es'],
      },
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
