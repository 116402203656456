import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IncidentArea } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class IncidentAreaApiService extends ResourceEntityApiService<IncidentArea> {
  resourceUrl = 'incident-areas';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
