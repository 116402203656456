import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EntityFile, Incident } from '../models';
import { environment } from '@environment';
import { map, tap } from 'rxjs/operators';
import { EntityFileApiHelperService } from './entity-file-api-helper.service';
import { EntityFileApiService } from './entity-file-api-service.type';
import { ResourceEntityApiService } from './resource-entity-api-service';
import { parseUtcDateProperties } from '../utils';

@Injectable()
export class IncidentApiService extends ResourceEntityApiService<Incident> implements EntityFileApiService {
  resourceUrl = 'incidents';

  constructor(
    protected http: HttpClient,
    protected entityFileApiHelperService: EntityFileApiHelperService
  ) {
    super(http);
  }

  parseEntity(entity) {
    parseUtcDateProperties(entity, ['formDate']);
    parseUtcDateProperties(entity, ['prescriptionDate']);
    if (entity.applicantId && entity.applicantDisplay) {
      entity.applicant = {
        id: entity.applicantId,
        entityDisplay: entity.applicantDisplay,
      };
    }
    return entity;
  }

  getAllFiles(entityParams, queryParams?) {
    return this.entityFileApiHelperService.getAllFiles(this, entityParams, queryParams);
  }

  getFileById(entityParams, fileId) {
    return this.entityFileApiHelperService.getFileById(this, entityParams, fileId);
  }

  uploadFile(entityParams, files) {
    return this.entityFileApiHelperService.uploadFile(this, entityParams, files);
  }

  deleteFile(entityParams, fileId) {
    return this.entityFileApiHelperService.deleteFile(this, entityParams, fileId);
  }

  downloadFile(file: EntityFile) {
    this.entityFileApiHelperService.downloadFile(this, file);
  }

  changeIncidentStatus(data: any, id: any) {
    return this.http
      .put<any>(`${environment.apiUrl}/${this.resourceUrl}/${id}/changeStatus`, data)
      .pipe(map((entity) => entity && this.parseEntity({ ...entity })));
  }
}
