import { Injectable } from '@angular/core';
import * as merge from 'deepmerge';
import isPlainObject from 'is-plain-object';

@Injectable()
export class UtilsService {
  mergeAll(items: any[]): any {
    return merge.all(items, {
      isMergeableObject: isPlainObject
    });
  }

  mergeArraysByProperty(property: string, ...configs: any[][]) {
    const result = configs.reduce<any>((obj, config) => {
      for (const field of config) {
        const key = field[property] as string;
        if (!obj[key]) {
          obj[key] = field;
        } else {
          obj[key] = this.mergeAll([obj[key], field]);
        }
      }
      return obj;
    }, {});
    return Object.values(result);
  }
}
