import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PharmacyBatchLog } from '@app/shared/api';
import { ApiList, Dispatch, PharmacyBatch, EntityFile, QueryParams } from '../models';
import { getHttpParams, parseUtcDateProperties, processApiList } from '../utils';
import { EntityFileApiHelperService } from './entity-file-api-helper.service';
import { ResourceEntityApiService } from './resource-entity-api-service';
import { environment } from '@environment';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class PharmacyBatchApiService extends ResourceEntityApiService<PharmacyBatch> {
  resourceUrl = 'pharmacy-batches';

  constructor(
    protected http: HttpClient,
    protected entityFileApiHelperService: EntityFileApiHelperService
  ) {
    super(http);
  }

  createBatches(data: any, params?: any): Observable<any> {
    return this.http.post<PharmacyBatch[]>(
      this.getCollectionUrl(data, params),
      data
    );
  }

  parseEntity(entity) {
    parseUtcDateProperties(entity, ['receptionDate']);
    parseUtcDateProperties(entity.items, ['date', 'prescriptionDate']);
    return entity;
  }

  getAllFiles(entityParams, queryParams?) {
    return this.entityFileApiHelperService.getAllFiles(
      this,
      entityParams,
      queryParams
    );
  }

  getFileById(entityParams, fileId) {
    return this.entityFileApiHelperService.getFileById(
      this,
      entityParams,
      fileId
    );
  }

  uploadFile(entityParams, files) {
    return this.entityFileApiHelperService.uploadFile(
      this,
      entityParams,
      files
    );
  }

  deleteFile(entityParams, fileId) {
    return this.entityFileApiHelperService.deleteFile(
      this,
      entityParams,
      fileId
    );
  }

  downloadFile(file: EntityFile) {
    this.entityFileApiHelperService.downloadFile(this, file);
  }

  liquidateBatch(data, pharmacyBatchId) {
    return this.http.put<any>(
      `${environment.apiUrl}/${this.resourceUrl}/${pharmacyBatchId}/liquidateBatch`,
      data
    );
  }

  getAllNewBatch(queryParams?: any) {
    return this.http.post<any>(`${this.getCollectionUrl()}/newBatch`, {
      queryParams,
    });
  }

  getLogs(pharmacyBatchId: number, queryParams?: QueryParams) {
    return this.http
      .get<ApiList<PharmacyBatchLog>>(
        `${this.getCollectionUrl()}/${pharmacyBatchId}/logs`,
        { params: getHttpParams(queryParams) }
      )
      .pipe(tap(processApiList));
  }

  getByIdForPrint(pharmacyBatchId) {
    return this.http.get<PharmacyBatch>((`${this.getCollectionUrl()}/${pharmacyBatchId}/print`)).pipe(
      map(entity => this.parseEntity({ ...entity })));
  }

  getItemsForPrint(pharmacyBatchId: number) {
    return this.http.get<Dispatch[]>(`${this.getCollectionUrl()}/${pharmacyBatchId}/items`);
  }
}
