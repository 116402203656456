import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';

@Injectable()
export class AlfabetaApiService {
  constructor(
    protected http: HttpClient,
  ) {}

  process() {
    return this.http.post<any>(`${environment.apiUrl}/alfabeta/process`, null);
  }
}
