export enum CancelEntityType {
  'Prescription' = 'Prescription',
  'Dispatch' = 'Dispatch',
  'PaymentOrder' = 'PaymentOrder',
  'PrescriptionItem' = 'PrescriptionItem',
}

export interface CancelEntity {
  entityType?: CancelEntityType;
  entityId?: number;
  observations?: string;
}
