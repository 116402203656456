import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiList, Diagnosis, QueryParams } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';
import { Observable } from 'rxjs';
import { environment } from '@environment';
import { getHttpParams, processApiList } from '../utils';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class DiagnosisApiService extends ResourceEntityApiService<Diagnosis> {
  resourceUrl = 'diagnoses';

  constructor(protected http: HttpClient) {
    super(http);
  }

  getDiagnosesByPathology(queryParams?: QueryParams, pathologyId?: any): Observable<ApiList<Diagnosis>> {
    if (pathologyId) {
      if (!queryParams) {
        queryParams = {};
      }
      queryParams.filter = `pathologyId eq ${pathologyId}`;
    }
    return this.http.get<ApiList<Diagnosis>>(
      `${environment.apiUrl}/diagnoses`,
      { params: getHttpParams(queryParams) }
    ).pipe(
      tap(processApiList),
      map(list => this.parseList(list))
    );
  }
}
