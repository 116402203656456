import { Beneficiary } from './beneficiary';
import { CoverageProgram } from './coverage-program';
import { Diagnosis } from './diagnosis';
import { Medication } from './medication';
import { Monodrug } from './monodrug';

export enum BeneficiaryTreatmentStatus {
  'Pending' = 'Pending',
  'Authorized' = 'Authorized',
  'Rejected' = 'Rejected',
  'Informed' = 'Informed',
}

export interface BeneficiaryTreatment {
  id?: number;
  beneficiaryId?: number;
  coverageProgramId?: number;
  diagnosisId?: number;
  diagnosis?: string;
  startDate?: Date;
  endDate?: Date;
  monodrugId?: number;
  potency?: string;
  prescribedAmount?: number;
  authorizedAmount?: number;
  frequency?: number;
  dosage?: number;
  coverage?: number;
  authorizedUserId?: number;
  specialCoverage?: boolean;
  prescribedBrandOnly?: boolean;
  treatmentMedications?: BeneficiaryTreatmentMedication[];
  observations?: string;
  status?: BeneficiaryTreatmentStatus;
  createdAt?: Date;
  updatedAt?: Date;
  beneficiary?: Beneficiary;
  coverageProgram?: CoverageProgram;
  monodrug?: Monodrug;
  requestedAmountCalculated?: number;
  consumedAmountCalculated?: number;
  diagnosisEntity?: Diagnosis;
}

export interface BeneficiaryTreatmentMedication {
  id?: number;
  medicationId?: number;
  units?: number;
  laboratory?: string;
  pvp?: number;
  quotedPrice?: number,
  prescribesBrand?: boolean;
  medication?: Medication;
}
