export function parseUtcDate(value: string | Date) {
  if (!value || value === '0000-00-00') {
    return null;
  }

  let date;
  if (typeof value === 'string') {
    date = new Date(value);
  } else {
    date = value;
  }

  return new Date(date.getTime() + (date.getTimezoneOffset() * 60000));
}

export function parseUtcDateProperties(value: any, properties: string[]) {
  if (!value) {
    return;
  }
  if (Array.isArray(value)) {
    for (const i of value) {
      parseUtcDateProperties(i, properties);
    }
    return;
  }
  for (const p of properties) {
    if (value[p]) {
      value[p] = parseUtcDate(value[p]);
    }
  }
}
