import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DosageForm } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class DosageFormApiService extends ResourceEntityApiService<DosageForm> {
  resourceUrl = 'dosage-forms';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
