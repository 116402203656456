import { AbstractControl, FormGroup } from '@angular/forms';

export function ArrayLength(controlName: string, min?: number, max?: number) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];

    if (control.value && typeof min === 'number' && control.value.length < min) {
      control.setErrors({ arrayLength: { current: control.value.length, min } });
    } else if (control.value && typeof max === 'number' && control.value.length > max) {
      control.setErrors({ arrayLength: { current: control.value.length, max } });
    } else {
      control.setErrors(null);
    }
  };
}

export function arrayLength(min?: number, max?: number) {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (control.value && typeof min === 'number' && control.value.length < min) {
      return { arrayLength: { current: control.value.length, min } };
    } else if (control.value && typeof max === 'number' && control.value.length > max) {
      return { arrayLength: { current: control.value.length, max } };
    }
    return null;
  };
}
