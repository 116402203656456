import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  hasLocalStorage: boolean;
  memoryCache: { [key: string]: string } = {};

  constructor() {
    this.hasLocalStorage = this.isLocalStorageSupported();
  }

  isLocalStorageSupported(): boolean {
    try {
      const testKey = 'testLocalStorage';
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  get(key: string): any {
    if (this.hasLocalStorage) {
      return this.localStorageGet(key);
    } else {
      return this.memoryGet(key);
    }
  }

  set(key: string, value: any): void {
    if (this.hasLocalStorage) {
      return this.localStorageSet(key, value);
    } else {
      return this.memorySet(key, value);
    }
  }

  remove(key: string): void {
    if (this.hasLocalStorage) {
      return this.localStorageRemove(key);
    } else {
      return this.memoryRemove(key);
    }
  }

  localStorageGet(key: string) {
    const value = localStorage.getItem(key);
    if (value === null) {
      return null;
    }
    try {
      return JSON.parse(value);
    } catch (e) {
      localStorage.removeItem(key);
      return null;
    }
  }

  localStorageSet(key: string, value: any): void {
    const stringValue = JSON.stringify(value);
    localStorage.setItem(key, stringValue);
  }

  localStorageRemove(key: string): void {
    localStorage.removeItem(key);
  }

  memoryGet(key: string) {
    return this.memoryCache[key];
  }

  memorySet(key: string, value: any): void {
    this.memoryCache[key] = value;
  }

  memoryRemove(key: string): void {
    delete this.memoryCache[key];
  }
}
