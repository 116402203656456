import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { BeneficiaryDiabetesCertificate } from '../models';
import { parseUtcDateProperties } from '../utils';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class BeneficiaryDiabetesCertificateApiService extends ResourceEntityApiService<BeneficiaryDiabetesCertificate> {
  constructor(protected http: HttpClient) {
    super(http);
  }

  protected getCollectionUrl(data?: any, params?: any): string {
    return `${environment.apiUrl}/beneficiaries/${data?.beneficiaryId || params.beneficiaryId}/diabetes-certificates`;
  }

  protected getItemUrl(data: any, params?: any): string {
    return `${environment.apiUrl}/beneficiaries/${data.beneficiaryId || params.beneficiaryId}/diabetes-certificates/${data.id}`;
  }

  parseEntity(entity) {
    parseUtcDateProperties(entity, [
      'certificateDate', 'ventricularHypertrophyDate', 'myocardialInfarctionDate',
      'heartFailureDate', 'CVADate', 'retinopathyDate', 'blindnessDate', 'peripheralNeuropathyDate', 'PVDDate',
      'amputationDate', 'kidneyDiseaseDate', 'dialysisDate', 'kidneyTransplantDate', 'bloodGlucoseDate', 'HBA1CDate',
      'LDLColesterolDate', 'triglyceridesDate', 'albuminToCreatinineRatioDate', 'systolicBPDate', 'diastolicBPDate',
      'serumCreatinineDate', 'funduscopyDate', 'weightDate', 'heightDate', 'waistCircumferenceDate'
    ]);
    return entity;
  }
}
