import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileSaverService } from '@app/shared/file-saver';
import { environment } from '@environment';
import { tap } from 'rxjs/operators';
import { ApiList, EntityFile } from '../models';
import { getHttpParams, processApiList } from '../utils';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class EntityFileApiHelperService {
  constructor(
    protected http: HttpClient,
    protected fileSaverService: FileSaverService,
  ) {}

  protected getFilesUrl(apiService: ResourceEntityApiService<any>, entityParams: any): string {
    const entityId = entityParams[apiService.entityIdField];
    return `${environment.apiUrl}/${apiService.resourceUrl}/${entityId}/files`;
  }

  protected getFileUrl(apiService: ResourceEntityApiService<any>, entityParams: any, fileId: number | string): string {
    const entityId = entityParams[apiService.entityIdField];
    return `${environment.apiUrl}/${apiService.resourceUrl}/${entityId}/files/${fileId}`;
  }

  getAllFiles(apiService: ResourceEntityApiService<any>, entityParams, queryParams) {
    return this.http.get<ApiList<EntityFile>>(
      this.getFilesUrl(apiService, entityParams),
      { params: getHttpParams(queryParams) }
    ).pipe(tap(processApiList));
  }

  getFileById(apiService: ResourceEntityApiService<any>, entityParams, fileId) {
    return this.http.get<EntityFile>(this.getFileUrl(apiService, entityParams, fileId));
  }

  uploadFile(apiService: ResourceEntityApiService<any>, entityParams, files) {
    const formData = new FormData();
    formData.append('file', files);
    return this.http.post<EntityFile>(this.getFilesUrl(apiService, entityParams), formData);
  }

  deleteFile(apiService: ResourceEntityApiService<any>, entityParams, fileId) {
    return this.http.delete(this.getFileUrl(apiService, entityParams, fileId));
  }

  downloadFile(apiService: ResourceEntityApiService<any>, file: EntityFile) {
    return this.http.get(file.fileUrl, {
      observe: 'response',
      responseType: 'blob'
    }).subscribe(res => {
      this.fileSaverService.saveAs(res.body, file.fileName);
    });
  }
}
