import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { AuditLog } from '../models';
import { ResourceEntityApiService } from './resource-entity-api-service';

@Injectable()
export class AuditLogApiService extends ResourceEntityApiService<AuditLog> {
  resourceUrl = 'audit-logs';

  constructor(protected http: HttpClient) {
    super(http);
  }

  getEntitiesList () {
    return this.http.get<any>(`${environment.apiUrl}/${this.resourceUrl}/entities/list`);
  }

}
